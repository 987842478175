import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import notify from "../notification/notification";
import Dashboard from "../dashboard";
import adminApiService from "../../services/admiApis.service";

export default function DownloadQRCode() {
  const [quantity, setQuantity] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const [language, setLanguage] = useState("");
  const [category, setCategory] = useState("");
  const [role, setRole] = useState("");

  const handleChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const delay = (time) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  };

  const handleDownload = async () => {
    if (quantity > 4000) {
      notify.error("Download Limit is 4000 QRCodes");
      return;
    }

    if (!quantity) {
      notify.error("Please enter Quantity");
      return;
    }

    if (!language) {
      notify.error("Please select a Language");
      return;
    }

    if (!category) {
      notify.error("Please select a Category");
      return;
    }

    try {
      setIsDownloading(true);
      const res = await adminApiService.generateQR(
        quantity,
        language,
        category
      );

      if (res.success) {
        await delay(10000);
        const downloadRes = await adminApiService.makeQRZip(language);
        console.log(downloadRes);

        const url = downloadRes.response;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", true);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
      setIsDownloading(false);
    } catch (err) {
      console.log(err);
      notify.error("Failed to download QR codes");
      setIsDownloading(false);
    }
    setQuantity("");
    setLanguage("");
    setCategory("");
  };

  useEffect(() => {
    setRole(JSON.parse(localStorage.getItem("adminUser")).role);
  }, []);

  return (
    <Dashboard>
      <Paper sx={{ mr: 1, ml: 1, mb: 2, mt: 2 }}>
        <Container sx={{ pt: 2, pr: 2, pl: 2, pb: 4 }}>
          {role === "Admin" ? (
            <Card sx={{ pb: 16 }}>
              <Toolbar
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                }}
              >
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Download QR Code
                </Typography>
              </Toolbar>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={quantity}
                    id="standard-basic-qr-code-quantity"
                    label="Enter QR Code Quantity"
                    name="qr-code-quantity"
                    type="number"
                    onChange={handleChange}
                    sx={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="language-select-label">
                      Select Language
                    </InputLabel>
                    <Select
                      labelId="language-select-label"
                      id="language-select"
                      value={language}
                      onChange={handleLanguageChange}
                    >
                      <MenuItem value="hindi">Hindi</MenuItem>
                      <MenuItem value="malayalam">Malayalam</MenuItem>
                      <MenuItem value="tamil">Tamil</MenuItem>
                      <MenuItem value="telugu">Telugu</MenuItem>
                      <MenuItem value="kannada">Kannada</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="category-select-label">
                      Select Category
                    </InputLabel>
                    <Select
                      labelId="category-select-label"
                      id="category-select"
                      value={category}
                      onChange={handleCategoryChange}
                    >
                      <MenuItem value="cars">Cars</MenuItem>
                      <MenuItem value="bikes">Bikes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ width: "100%", display: "block" }}
                >
                  <LoadingButton
                    loading={isDownloading}
                    disabled={isDownloading}
                    variant="contained"
                    onClick={handleDownload}
                  >
                    Download
                  </LoadingButton>
                </Grid>
              </Grid>
            </Card>
          ) : null}
        </Container>
      </Paper>
    </Dashboard>
  );
}
