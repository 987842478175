import { React, useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  TablePagination,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import adminApiService from "../../services/admiApis.service";
import notify from "../notification/notification";
import Dashboard from "../dashboard";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: "#01579b",
    "& .MuiTableSortLabel-icon": {
      color: "#ffffff !important",
    },
  },
  tabelHeaderCell: {
    color: "#ffffff !important",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: "qr_code_id",
    numeric: false,
    disablePadding: false,
    label: "QR Code Id",
    isSort: false,
  },
  {
    id: "mobile_no",
    numeric: false,
    disablePadding: false,
    label: "Phone Number",
    isSort: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "name",
    isSort: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "vechile_type",
    numeric: false,
    disablePadding: false,
    label: "Vehicle Type",
  },
  {
    id: "vechile_no",
    numeric: false,
    disablePadding: false,
    label: "Vehicle Number",
  },
  // {
  //   id: "vechile_no",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Vehicle Number",
  // },
  {
    id: "chassis_number",
    numeric: false,
    disablePadding: false,
    label: "Chasis Number",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Registered Date",
  },
  {
    id: "expiry_date",
    numeric: false,
    disablePadding: false,
    label: "Expiry Date",
  },
  
];

export default function ReplacementOrderTrack() {
  //  const history = useHistory();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("qr_code_id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [list, setList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchedVal, setSearchedVal] = useState("");
  const [paginationCount, setPaginationCount] = useState(1);
  const dataList = [];

  const [searchText, setSearchText] = useState("");
  const [searchOption, setSearchOption] = useState("");
  const [data, setData] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function EnhancedTableHead(props) {
    const classes = useStyles();
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.isSort ? (
                  <>
                    <TableSortLabel
                      className={classes.tabelHeaderCell}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </>
                ) : (
                  <span className="table-headCell" style={{ color: "#fff" }}>
                    {headCell.label}
                  </span>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </>
    );
  }

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
   // console.log("searchtxt =", searchText);
  };

  const handleOptionChange = (event) => {
    setSearchOption(event.target.value);
 //   console.log("searchOption =", searchOption);
  };
  useEffect(() => {
  //  console.log("Selected option:", searchOption);
  }, [searchOption]);

  const handleSearch = async () => {
    if (
      searchOption === "vehicleNo" ||
      searchOption === "PhoneNo" ||
      searchOption === "QrID"
    ) {
      try {
        const res = await adminApiService.getVehicleDetails(
          searchText,
          searchOption
        );

        if (res.success) {
          setData(res.response);
        //  console.log("Response:", res);
        } else {
        //  console.log("Error:", res.error);
          notify.error(res.message);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleClear = () => {
    setSearchText("");
    data.length = 0;
  };
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  return (
    <>
      <Dashboard>
        <div
          style={{
            marginTop: "2rem",
            marginLeft: "5rem",
            display: "flex",
            alignItems: "start",
          }}
        >
          {/* <label style={{fontWeight:"600"}} htmlFor="search">Search</label> */}
          <input
            value={searchText}
            onChange={handleSearchChange}
            className="search-input-box"
            id="search"
            type="text"
            placeholder=""
          />
          <div style={{ display: "flex", alignItems: "end" }}>
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>{" "}
            &nbsp;
            <Button variant="contained" onClick={handleClear}>
              Clear
            </Button>
          </div>
        </div>

        <div style={{ marginTop: "2rem", marginLeft: "6rem" }}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={searchOption}
              onChange={handleOptionChange}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="vehicleNo"
                control={<Radio checked={searchOption === "vehicleNo"} />}
                label="Vehicle Number / Chasis Number"
              />
              <FormControlLabel
                value="PhoneNo"
                control={<Radio checked={searchOption === "PhoneNo"} />}
                label="Phone Number"
              />
              <FormControlLabel
                value="QrID"
                control={<Radio checked={searchOption === "QrID"} />}
                label="QR Code ID"
              />
            </RadioGroup>
          </FormControl>
        </div>

        {data && data.length > 0 ? (
          <>
            <Box className="content-wrapper" mt={2}>
              <Paper sx={{ mr: 1, ml: 1, mb: 2 }}>
                <Toolbar
                  sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{ flex: "1 1 100%" }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                  >
                    Vehicle Details
                  </Typography>
                </Toolbar>
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={() => {}}
                      onRequestSort={handleRequestSort}
                      rowCount={list.length}
                    />
                    <TableBody>
                      {data.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            style={{ cursor: "pointer" }}
                            tabIndex={-1}
                            key={row.index}
                          >
                            <TableCell align="left">{row.qr_code_id}</TableCell>
                            <TableCell align="left">{row.mobile_no}</TableCell>
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left" id={labelId}>
                              {row.email}
                            </TableCell>
                            <TableCell align="left" id={labelId}>
                              {row.vechile_type}
                            </TableCell>
                            <TableCell align="left" id={labelId}>
                              {row.vechile_no}
                            </TableCell>
                            <TableCell align="left" id={labelId}>
                              {row.chassis_number}
                            </TableCell>
                            <TableCell align="left" id={labelId}>
                              {row.createdAt
                                ? moment(row.createdAt).format("DD/MM/YYYY")
                                : ""}
                            </TableCell>  
                            <TableCell align="left" id={labelId}>
                              {row.expiry_date
                                ? moment(row.expiry_date).format("DD/MM/YYYY")
                                : ""}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </>
        ) : null}
      </Dashboard>
    </>
  );
}

//  <TablePagination
//                 rowsPerPageOptions={[10, 25, 50]}
//                 component="div"
//                 count={paginationCount}
//                 rowsPerPage={rowsPerPage}
//                 page={page}
//                 onPageChange={handleChangePage}
//                 onRowsPerPageChange={handleChangeRowsPerPage}
//               />
