import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Toolbar from "@mui/material/Toolbar";
import Card from "@mui/material/Card";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

import Dashboard from "../dashboard";
import adminApiService from "../../services/admiApis.service";
import notify from "../notification/notification";

import { useHistory, useParams } from "react-router-dom";

export default function UpdateReplacementOrder() {
  const history = useHistory();
  const [status, setStatus] = useState("");
  const [courierName, setCourierName] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const params = useParams();

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const updateOrder = async (event) => {
    if (status === "") {
      alert("Please select status");
      return;
    }
    const data = {
      replacement_order_id: params.orderNo,
      replacement_order_status: status,
      courier_company_name: courierName,
      tracking_number: trackingNumber,
    };
    try {
      const res = await adminApiService.updateReplacementOrder(data);
      if (res.success) {
        notify.sucess("Updated Order Status");
        history.push("/replacementOrder");
      }
    } catch (err) {
      notify.error("Updated Order Status Failed");
      console.log(err);
    }
  };

  const handleCourierName = (event) => {
    setCourierName(event.target.value);
  };

  const handleTrackingnNumber = (event) => {
    setTrackingNumber(event.target.value);
  };

  return (
    <Dashboard>
      <div className="update-order-container">
        <Card sx={{ pb: 16 }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
              Update Replacement Order
            </Typography>
          </Toolbar>
          <Grid container spacing={3} sx={{ pl: 2 }}>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
                <Select value={status} onChange={handleChange} label="Select Status" inputProps={{ "aria-label": "Without label" }}>
                  <MenuItem value={"Delivered"}>Delivered</MenuItem>
                  <MenuItem value={"Dispatch"}>Dispatch</MenuItem>
                  <MenuItem value={"Returned"}>Returned</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {status === "Dispatch" && (
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id="standard-basic-courier_company_name"
                  label="Courier Name"
                  name="courier_company_name"
                  type="text"
                  InputProps={{ min: "1", max: "12" }}
                  onChange={handleCourierName}
                  sx={{ width: "100%" }}
                />
              </Grid>
            )}

            {status === "Dispatch" && (
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id="standard-basic-tracking_number"
                  label="Tracking Number"
                  name="tracking_number"
                  type="text"
                  InputProps={{ min: "1", max: "12" }}
                  onChange={handleTrackingnNumber}
                  sx={{ width: "100%" }}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6} lg={12}>
              <Button variant="contained" onClick={updateOrder}>
                Update
              </Button>
            </Grid>
          </Grid>
        </Card>
      </div>
    </Dashboard>
  );
}
