import {
  put,
  get,
  postWithoutToken,
  post,
  postRequestToSendOTP,
  deleteWithToken,
  deleteWithoutToken,
} from "./api.service";

const adminApiService = {
  /* Admin API HIT */
  authenticateUser(payload) {
    return postWithoutToken("admin-signin", payload);
  },

  getAllOrders() {
    return get("orders");
  },

  getRelation() {
    return get("relations");
  },

  getReplacementOrders() {
    return get("replacement-qr-orders");
  },

  getReplacementOrdersDatewise(startDate, endDate) {
    return get(
      `replacement-qr-orders?start_date=${startDate}&end_date=${endDate}`
    );
  },

  deleteVehicleType(data) {
    return deleteWithToken("remove-vechile-type", data);
  },

  deleteReferralCode(id) {
    return deleteWithToken(`delete-referral-code/${id}`);
  },
  // async deleteVehicleType(data) {
  //   const url = 'https://3.111.76.3/v1/remove-vechile-type';
  //   const requestOptions = {
  //     method: 'DELETE',
  //     headers: {
  //       //'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im5hdmVlbkBnbWFpbC5jb20iLCJhZG1pbl9pZCI6MiwiaWF0IjoxNjk0Njc0MDU0LCJleHAiOjE2OTQ3NjA0NTR9.WZyGIykfLMkU8Af9HnHKJ6fabdFjdoh2nRo1WYMIOCg',
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(data), // Include the data in the request body
  //   };

  //   try {
  //     const response = await fetch(url, requestOptions);

  //     if (response.ok) {
  //       const responseData = await response.json();
  //       return responseData;
  //     } else {
  //       throw new Error(`Request failed with status: ${response.status}`);
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // },

  getCustomerOrders(agent = false) {
    return get(`customer-orders?agent=${agent}`);
  },

  // getAllAgentOrders(phone_number=null) {

  //   return phone_number == null ? get(`agent-orders`) : get(`agent-orders${phone_number}`);

  // },
  //  getCustomerDetails(phone_number=null) {

  //   return phone_number == null ? get(`admin/customer-list`) : get(`admin/customer-list${phone_number}`);

  // },
  sendOtp(payload) {
    return postRequestToSendOTP("common/send-otp", payload);
  },
  getOtp(data) {
    return postRequestToSendOTP("common/verify-otp", data);
  },

  // getCustomerDetails(page,size,searchedVal) {
  //   return get(`admin/customer-list?page=${page}&size=${size}&phone_number=${searchedVal}&qr_code_id=${searchedVal}&vechile_no=${searchedVal}&name=${searchedVal}`);

  // },
  // getCustomerDetails(page,size,phone_number,qr_code_id,vechile_no,name) {
  //   return get(`admin/customer-list?page=${page}&size=${size}&phone_number=${phone_number}&qr_code_id=${qr_code_id}&vechile_no=${vechile_no}&name=${name}`);

  // },

  getCustomerDetails(page, size, searchedVal) {
    let url = `admin/customer-list?page=${page}&size=${size}`;

    // Add optional search parameters if provided
    if (searchedVal && searchedVal !== "") {
      url += `&phone_number=${searchedVal}`;
    }
    if (searchedVal && searchedVal !== "") {
      url += `&qr_code_id=${searchedVal}`;
    }
    if (searchedVal && searchedVal !== "") {
      url += `&vechile_no=${searchedVal}`;
    }
    if (searchedVal && searchedVal !== "") {
      url += `&name=${searchedVal}`;
    }

    return get(url);
  },

  // getCustomerDetails(page, size, searchValue) {
  //   let url = `admin/customer-list?page=${page}&size=${size}`;

  //   // Add the search parameter based on the user input
  //   if (searchValue && searchValue !== '') {
  //     // Check if the search value matches any specific parameter format
  //     if (searchValue.match(/^\d+$/)) {
  //       url += `&phone_number=${searchValue}`;
  //     } else if (searchValue.match(/^\w+$/)) {
  //       url += `&vechile_no=${searchValue}`;
  //     } else if (searchValue.match(/^\d+$/)) {
  //       url += `&qr_code_id=${searchValue}`;
  //     } else {
  //       url += `&name=${searchValue}`;
  //     }
  //   }

  //   return get(url);
  // },
  updateOrderDetails(data) {
    return put("admin/update-order", data);
  },

  updateVehicleDeetsOrder(data) {
    return put("admin/update-vechile-order", data);
  },

  updateCustomerDetails(qrId, data) {
    return put(`customer/qr-update/${qrId}`, data);
  },
  // getAllAgentOrders(agent=true) {

  //   return get(`/orders?agent=${agent}`);

  // },
  getAllAgentOrders(agent = true, startDate, endDate) {
    return get(`agent-orders?agent=${agent}`);
  },
  getAllAgentOrdersDatewise(startDate, endDate, agent = true) {
    return get(
      `agent-orders?start_date=${startDate}&end_date=${endDate}&agent=${agent}`
    );
  },
  getAllCustomerOrdersDatewise(startDate, endDate, agent = false) {
    return get(
      `customer-orders?start_date=${startDate}&end_date=${endDate}&agent=${agent}`
    );
  },
  // getAllAgentOrdersDate(agent=true,startDate,endDate) {

  //   return get(`/agent-orders?agent=${agent}&start_date=${startDate}&end_date=${endDate}`);

  // },

  viewOrder(id) {
    return get(`order-detail/${id}`);
  },

  viewCustomerDetails(id) {
    return get(`admin/customer-detail/${id}`);
  },
  viewReplacementOrder(id) {
    return get(`replacement-qr-order-detail/${id}`);
  },

  generateQR(quantity, language, category) {
    return get(
      `generate-qr/${quantity}?template=${language}&category=${category}`
    );
  },

  makeQRZip(language) {
    return get(`made-qr-zip?template=${language}`);
  },

  getAllRefferalCodes() {
    return get("referral-codes");
  },

  getVehicleType() {
    return get("vechile-type");
  },

  // getAllQRCodes() {
  //   return get("admin/qr-list");  //qr id is optional but page and limit is required
  // },

  getAllQRCodes(qr_code_id, page, size) {
    return get(
      `admin/qr-list?qr_code_id=${qr_code_id}&page=${page}&size=${size}`
    );
    // return get(`admin/qr-list?${qr_code_id}=${page}=${size}`);  //qr id is optional but page and limit is required
  },

  getInactiveQRCodes(payload) {
    return post("qr-list", payload);
  },

  activateQr(data) {
    return post("activate-qr-by-agent", data);
  },

  updateOrder(payload) {
    return put("order/status-update", payload);
  },

  updateReplacementOrder(payload) {
    return put("replacement-qr-orders/status-update", payload);
  },

  updateReferralCodeStatus(payload) {
    return put("referral-code-status-update", payload);
  },

  addReferralCode(payload) {
    return post("add-referral-code", payload);
  },

  addVehicle(payload) {
    return post("add-vechile-type", payload);
  },

  userDetails(qrCode) {
    return get(`scan-qr/${qrCode}`);
  },

  getSmsHistory() {
    return get("sms-history");
  },

  getSmsContent() {
    return get("sms");
  },

  getCallHistory() {
    return get("call-owner-history");
  },

  getEmergencyCallHistory() {
    return get("emergency-call-history");
  },

  addQRIntoOrder(payload) {
    return post("add-qr-order", payload);
  },

  addQRIntoReplacementOrder(payload) {
    return put("add-qr-into-replacement-order", payload);
  },

  viewQRCodeDetails(id) {
    return get(`user-qr-detail?qr_code=${id}`);
  },

  getVehicleNumbers(order_id) {
    return get(`get-order-unassigned-vechile/${order_id}`);
  },
  getReplacementQrList(qr_code_id) {
    return get(`replacement-qr-list/${qr_code_id}`);
  },

  updatePassword(payload) {
    return put("admin/password-update", payload);
  },

  getVechileType() {
    return get("vechile-type");
  },

  getVehicleDetails(searchText, searchOption) {
    let urlData =
      searchOption == "vehicleNo"
        ? `vehicle_no=${searchText}`
        : searchOption == "PhoneNo"
        ? `mobile_no=${searchText}`
        : `qr_code_id=${searchText}`;
    //return get(`fetch-vehicle-detail?vehicle_no=${searchOption=='vehicleNo'? searchText : null}&mobile_no=${searchOption=='PhoneNo'? searchText : null}&qr_code_id=${searchOption=='QrID'? searchText : null}`);
    return get(`fetch-vehicle-detail?${urlData}`);
  },

  updateVehicleType(payload) {
    return put("update-vechile", payload);
  },

  updatePaymentStatus(payload) {
    return put("payment/status-update", payload);
  },

  updateReplacementPaymentStatus(payload) {
    return put("replacement-qr-payment/status-update", payload);
  },

  getExcelData(payload) {
    //  console.log(payload);
    return post("download-excel", { orders: payload });
  },

  getReplacementExcelData(payload) {
    //  console.log(payload);
    return post("replacement-download-excel", { replacement_orders: payload });
  },

  directReplacementAssignQr(payload) {
    return post("admin/replacement-order-qr", payload);
  },

  //  uploadCsv(apiKey,payload){
  //   console.log("apiky",apiKey);
  //   console.log("palod",payload);

  //   const headers = {
  //     'x-api-key': apiKey,
  //     'Content-Type': 'application/json',
  //   };
  //   return post("activate-rqr-by-csv-upload",payload,headers);
  //  }
};

export default adminApiService;
