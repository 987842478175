import { React, useState, useEffect } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Toolbar, Typography, Paper, IconButton,TablePagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import adminApiService from "../../services/admiApis.service";
import { useHistory } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Dashboard from "../dashboard";
import moment from "moment";
import { useNavigate  } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: "#01579b",
    "& .MuiTableSortLabel-icon": {
      color: "#ffffff !important",
    },
  },
  tabelHeaderCell: {
    color: "#ffffff !important",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "qr_code_id",
    numeric: false,
    disablePadding: false,
    label: "QR Code Id",
    isSort: true,
  },
  {
    id: "order_id",
    numeric: false,
    disablePadding: false,
    label: "Order Id",
    isSort: true,
  },
  {
    id: "qr_code",
    numeric: false,
    disablePadding: false,
    label: "QR Code",
  },
  {
    id: "expiry_date",
    numeric: false,
    disablePadding: false,
    label: "Expiry Date",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  // {
  //     id: "view-replacement-order",
  //     numeric: false,
  //     disablePadding: false,
  //     label: "Replacement-QR-Details",
  //   },
];

function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.numeric ? "right" : "left"} padding={headCell.disablePadding ? "none" : "normal"} sortDirection={orderBy === headCell.id ? order : false}>
            {headCell.isSort ? (
              <>
                <TableSortLabel className={classes.tabelHeaderCell} active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
                  {headCell.label}
                </TableSortLabel>
              </>
            ) : (
              <span className="table-headCell" style={{ color: "#fff" }}>
                {headCell.label}
              </span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    </>
  );
}

export default function QRListTable() {
  const history = useHistory();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("qr_code_id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [qrList, setQRList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchedVal, setSearchedVal] = useState("");
const [paginationCount,setPaginationCount]=useState(1);
 const fetchData= async ()=> {
    //  console.log(qr_code_id,page,size);

   
      const res = await adminApiService.getAllQRCodes(searchedVal,page,rowsPerPage);
      // console.log(res);
      if (res.success) {
      setPaginationCount(res.response.count)
      
        setQRList(res.response.qr);
        setFilterData(res.response.qr);
      } else {
        setQRList([]);
        setFilterData([]);
      }
    }

  useEffect(() => {
     fetchData().catch(console.error);
  }, [page,rowsPerPage,searchedVal]);


  // const fetchData= async ()=> {
  //   //  console.log(qr_code_id,page,size);
    
  //     const res = await adminApiService.getAllQRCodes();
  //     // console.log(res);
  //     if (res.success) {
  //       setQRList(res.response);
  //       setFilterData(res.response);
  //     } else {
  //       setQRList([]);
  //       setFilterData([]);
  //     }
  //   }

  // useEffect(() => {
  //    fetchData().catch(console.error);
  // }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    //console.log(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
   // console.log(parseInt(event.target.value));
  };

  const viewQRDetails = (event, data) => {
    history.push(`/viewQRDetails/${data.qr_code}`);
  };

  // const gotoReplacementDetails = (event,qr_code_id) => {
  //   history.push(`/trackReplacementOrder/${qr_code_id}`);
  // }

  // const gotoReplacementDetails = () => {
  //   navigate("/trackReplacementOrder");
  // }


  //working code
  // const viewReplacementQrDetails = (event,data) => {
  
  //   history.push(`/trackReplacementOrder/${data.qr_code_id}`);
  // }

  const handleSearch = (event) => {
    setPage(0);
    setSearchedVal(event.target.value)
    // setSearchedVal(event.target.value);
    // const filterData = qrList.filter((ele) => {
    //   return (
    //     ele.qr_code_id.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()) || ele.qr_code.toString().toLowerCase().includes(event.target.value.toString().toLowerCase())
    //   );
    // });
    // setFilterData(filterData);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - qrList.length) : 0;

  return (
    <>
      <Dashboard>
        {qrList && qrList.length > 0 ? (
          <Box className="content-wrapper" mt={2}>
            <Paper sx={{ mr: 1, ml: 1, mb: 2 }}>
              <Toolbar
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  mb: 2,
                }}
              >
                <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
                  QR List
                </Typography>
                <label htmlFor="search">Search</label>
                <input className="search-input-box" id="search" type="text" onChange={handleSearch} placeholder="Search by Qr Code Id" />
              </Toolbar>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead order={order} orderBy={orderBy} onSelectAllClick={() => {}} onRequestSort={handleRequestSort} rowCount={qrList.length} />
                  <TableBody>
                    {stableSort(filterData, getComparator(order, orderBy))
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow style={{ cursor: "pointer" }} tabIndex={-1} key={row.index} >
                            <TableCell align="left" onClick={(event) => viewQRDetails(event, row)}>{row.qr_code_id}</TableCell>
                            <TableCell align="left" onClick={(event) => viewQRDetails(event, row)}>{row.order_id ? row.order_id : ""}</TableCell>
                            <TableCell align="left" id={labelId} onClick={(event) => viewQRDetails(event, row)}>
                              {row.qr_code}
                            </TableCell>
                            <TableCell align="left" onClick={(event) => viewQRDetails(event, row)}>{row.expiry_date ? moment(row.expiry_date).format("DD/MM/YYYY") : ""}</TableCell>
                            <TableCell align="left" onClick={(event) => viewQRDetails(event, row)}>{row.status ? row.status : "InActive"}</TableCell>
                           {/*  <TableCell align="left">
                            <IconButton onClick={(event) => viewReplacementQrDetails(event,row)} >
                            <RemoveRedEyeOutlinedIcon  color="primary" />
                            </IconButton> 
                            </TableCell>*/}
                          </TableRow>
                        );
                      })}

                    {/* {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={paginationCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        ) : null}
      </Dashboard>
    </>
  );
}
