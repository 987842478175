import React, { useState, useEffect } from "react";
import adminApiService from "../../services/admiApis.service";
import {
  Box,
  Toolbar,
  Typography,
  Card,
  Grid,
  TextField,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import { useParams } from "react-router-dom";
import notify from "../notification/notification";
// import {  useHistory } from 'react-router';
import moment from "moment";
import Dashboard from "../dashboard";

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        View Replacement Order Details
      </Typography>
    </Toolbar>
  );
};

export default function ViewReplacementOrder() {
  const [orderList, setOrderList] = useState();
  const [status, setStatus] = useState("");
  const params = useParams();
  const [orderStatus, setOrderStatus] = useState("");
  const [role, setRole] = useState("");
  const [courierStatus, setcourierStatus] = useState("");

  useEffect(() => {
    setRole(JSON.parse(localStorage.getItem("adminUser")).role);
  }, []);

  // const history = useHistory();

  useEffect(async () => {
    //  function fetchData() {
    const res = await adminApiService.viewReplacementOrder(params.orderNo);
    //console.log(res.response);
    if (res.success === true) {
     // console.log(res.response);
      setOrderList(res.response);
   //   console.log(orderList);
    }
    // }

    //fetchData().catch(console.error);
  }, []);

  const handleChange = (event) => {
    const selectedPaymentStatus = event.target.value
    setStatus(selectedPaymentStatus);
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      replacement_payment_status: event.target.value,
    }));
  };
  const [formfield, setFormfield] = useState({
    name: "",
    email: "",
    mobile_no: "",
    street: "",
    district: "",
    state: "",
    area: "",
    building_name: "",
    building_no: "",
    pincode: "",
    razorpay_order_id: "",
    razorpay_payment_id: "",
    replacement_order_status: "",
    tracking_number: "",
    courier_company_name: "",
    street: "",
    chassis_number: "",
    courier_status:"",
    replacement_payment_status:"",
  });

  const handleCourierChange = (event) => {
    const selectedValue =event.target.value
    setcourierStatus(selectedValue);
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      courier_status: event.target.value,
    }));
  };


  const handleRazorpayId = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      razorpay_order_id: e.target.value,
    }));
  };

  const handleRazorpaymentId = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      razorpay_payment_id: e.target.value,
    }));
  };

  const handleAmount = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      amount: e.target.value,
    }));
  };
  const handleStreet = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      street: e.target.value,
    }));
  };
  const handleState = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      state: e.target.value,
    }));
  };
  const handleArea = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      area: e.target.value,
    }));
  };
  const handleDistrict = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      district: e.target.value,
    }));
  };
  const handlePincode = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      pincode: e.target.value,
    }));
  };
  const handleName = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      name: e.target.value,
    }));
  };
  const handleBuildingName = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      building_name: e.target.value,
    }));
  };
  const handleBuildingNo = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      building_no: e.target.value,
    }));
  };
  const handleEmail = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      email: e.target.value,
    }));
  };
  const handlePhone = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      mobile_no: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   // console.log(formfield);
  };
  // const handleOrderStatus = (e) => {
  //   setFormfield((prevFormfield) => ({
  //     ...prevFormfield,
  //     order_status: e.target.value,
  //   }));
  // };

  const handleCourierName = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      courier_company_name: e.target.value,
    }));
  };
  const handleTrackingNo = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      tracking_number: e.target.value,
    }));
  };

  const updatePaymentStatus = async (event) => {
    // if (status === "") {
    //   alert("Please select status");
    //   return;
    // }
    // console.log(params.orderNo);
    const data = {
      replacement_order_id: params.orderNo,
      replacement_payment_status: status,
    };
    //console.log(data);
    try {
      const res = await adminApiService.updateReplacementPaymentStatus(data);
      // console.log("res",res);
      if (res.success) {
        notify.sucess("Updated Payment Status");
        // history.push("/");
      }
    } catch (err) {
      notify.error("Updated Payment Status Failed");
     // console.log(err);
    }
  };
  
  const submit = async (e) => {
    // e.preventDefault();
  //  e.stopPropagation(); 
  
    const data = {
      order_id: params.orderNo,
      name: formfield.name,
      email: formfield.email,
      mobile_no: formfield.mobile_no,
      //  vechile_no: formfield.vechile_no,
      //  vehicle_type: formfield.vType,
      emergency_contact1: formfield.emergency_contact1,
      emergency_contact2: formfield.emergency_contact2,
      emergency_contact3: formfield.emergency_contact3,
      street: formfield.street,
      district: formfield.district,
      state: formfield.state,
      area: formfield.area,
      building_name: formfield.building_name,
      building_no: formfield.building_no,
      pincode: formfield.pincode,
      gender: formfield.gender,
      relation1: formfield.relation1,
      relation2: formfield.relation2,
      relation3: formfield.relation3,
      blood_group: formfield.blood_group,
      street: formfield.street,
      district: formfield.district,
      state: formfield.state,
      area: formfield.area,
      building_name: formfield.building_name,
      building_no: formfield.building_no,
      pincode: formfield.pincode,
      razorpay_order_id: formfield.razorpay_order_id,
      razorpay_payment_id: formfield.razorpay_payment_id,

      quantity: formfield.quantity,
      payment_amount: formfield.payment_amount,
      order_status: orderStatus,
      tracking_number: formfield.tracking_number,
      courier_company_name: formfield.courier_company_name,
      courier_status : courierStatus,
      payment_status: status,
     // chassis_number:formfield.chassis_number
      // vechile_insurance_company_name: formfield.vechile_insurance_company_name,
      // vechile_insurance_no: formfield.vechile_insurance_no,
      // v_insu_valid_from: formfield.v_insu_valid_from,
      // v_insu_valid_to: formfield.v_insu_valid_to,
      // health_insurance_company_name: formfield.health_insurance_company_name,
      // health_insurance_no: formfield.health_insurance_no,
      // h_insu_valid_from: formfield.h_insu_valid_from,
      // h_insu_valid_to: formfield.h_insu_valid_to,
    };


    
 
    const res = await adminApiService.updateOrderDetails(data);
    if (res.success) {
     // console.log(res)
      notify.sucess("Details updated successfully");
    } else {
      notify.error("update failed try again");
    }

    // if (status != "") {
    //   updatePaymentStatus();
    // }
    // if (courierStatus != "") {
    //   updateCourierStatus();
    // }
  };


  // const handleOrderStatusChange = (event) => {
  //   setOrderStatus(event.target.value);
  // };
  const handleOrderStatusChange = (event) => {
    const selectedOrderStatus = event.target.value
    setOrderStatus(selectedOrderStatus);
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      replacement_order_status: event.target.value,
    }));
  };

  const updateCourierStatus = async (event) => {
    if (courierStatus === "") {
      alert("Please select status");
      return;
    }
    // if (courierStatus === "Dispatch" && formfield.courier_company_name ==="" || formfield.tracking_number ==="") {
    //   alert("Please Add Courier Name & Tracking No");
    //   return ;
    // }
    const data = {
      order_id: params.orderNo,
      status: courierStatus,
      courier_company_name: formfield.courier_company_name,
      tracking_number: formfield.tracking_number,
    };
    try {
      const res = await adminApiService.updateOrder(data);
      if (res.success == true) {
       // notify.sucess("Updated Courier Status");
       notify.sucess(res.message);

       // history.push(`/viewOrder/${params.orderNo}`);
//history.push("/order");
      }else{
        notify.error(res.message);
      }
    } catch (err) {
     // notify.error("Courier Status Update Failed");
     notify.error(err.message);
      
    // console.log(err);
    }
  };
  return (
    <>
      <Dashboard>
        <Box className="content-wrapper" sx={{ mb: 2, mt: 2 }}>
          <Paper sx={{ mr: 1, ml: 1, mb: 2 }}>
            <EnhancedTableToolbar />
            {
              //Object.keys(order).length > 0 &&
              orderList?.order &&
                [orderList?.order].map((orderItem, index) => (
                  <Card className="mt-2">
                    <form>
                      <Grid container mb={2}>
                        <Typography
                          sx={{ flex: "1 1 100%", ml: 2 }}
                          variant="h6"
                          id="tableTitle"
                          component="div"
                        >
                          Customer Details
                        </Typography>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="outlined-basic"
                            label="Name"
                            name="name"
                            type="text"
                            onChange={handleName}
                            defaultValue={orderItem?.name || " "}
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="outlined-basic"
                            label="Phone Number"
                            name="phone_number"
                            type="tel"
                            onChange={handlePhone}
                            defaultValue={orderItem?.phone_number || " "}
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic"
                            label="Email"
                            name="email"
                            type="email"
                            onChange={handleEmail}
                            defaultValue={orderItem.email || " "}
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-street"
                            name="street"
                            label="Street"
                            onChange={handleStreet}
                            defaultValue={orderItem.street || " "}
                            type="text"
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-vechile_type"
                            name="vechile_type"
                            label="Area"
                            type="text"
                            onChange={handleArea}
                            defaultValue={orderItem.area || " "}
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-vechile_type"
                            name="vechile_type"
                            label="Building Name"
                            type="text"
                            onChange={handleBuildingName}
                            defaultValue={orderItem.building_name || " "}
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-bldgNo"
                            name="bldgNo"
                            label="Building Number"
                            type="text"
                            onChange={handleBuildingNo}
                            defaultValue={orderItem?.building_no || " "}
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-city"
                            name="city"
                            label="City/District"
                            type="text"
                            onChange={handleDistrict}
                            defaultValue={orderItem?.district || " "}
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-state"
                            name="State"
                            label="State"
                            onChange={handleState}
                            defaultValue={orderItem?.state || " "}
                            type="text"
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-pincode"
                            name="pincode"
                            label="Pin Code"
                            onChange={handlePincode}
                            defaultValue={orderItem?.pincode || " "}
                            type="number"
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-address"
                            name="address"
                            label="Address"
                            defaultValue={orderItem?.address || " "}
                            type="text"
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        </Grid>

                        <hr style={{ width: "100%" }} />

                        <Typography
                          sx={{ flex: "1 1 100%", ml: 2 }}
                          variant="h6"
                          id="tableTitle"
                          component="div"
                        >
                          Vehicle & QR Details
                        </Typography>

                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-quantity"
                            name="quantity"
                            label="Damaged QR ID"
                            type="number"
                            defaultValue={orderItem.old_qr_code || " "}
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-quantity"
                            name="quantity"
                            label="Newly Assigned QR ID"
                            type="number"
                            defaultValue={orderItem?.qr_code_id || " "}
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-vechile_no"
                            name="vechile_no"
                            label="Vehicle No."
                            defaultValue={orderItem.vechile_no || " "}
                            type="text"
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid
                              item
                              xs={4}
                              sm={6}
                              lg={3}
                              sx={{ ml: 2, mt: 2 }}
                            >
                              <TextField
                                id="standard-basic-chassis_number"
                                name="chassis_number"
                                label={`chassis No-${index + 1}`}
                                type="text"
                               
                                defaultValue={orderItem?.chassis_number || " "}
                                sx={{ width: "100%" }}
                                InputProps={{
                                  readOnly: false,
                                }}
                              />
                            </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-vehicle-type"
                            name="vehicle-type"
                            label="Vehicle Type"
                            defaultValue={orderItem?.vechile_type || " "}
                            type="text"
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>

                        <hr style={{ width: "100%" }} />

                        <Typography
                          sx={{ flex: "1 1 100%", ml: 2 }}
                          variant="h6"
                          id="tableTitle"
                          component="div"
                        >
                          Payment Details
                        </Typography>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-amount"
                            name="amount"
                            label="Amount"
                            type="text"
                            onChange={handleAmount}
                            defaultValue={orderItem?.amount || " "}
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-razorpay_order_id"
                            name="razorpay_order_id"
                            label="RazorPay Order Id"
                            defaultValue={orderItem?.razorpay_order_id || " "}
                            type="text"
                            onChange={handleRazorpayId}
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-razorpay_order_id"
                            name="razorpay_payment_id"
                            label="RazorPay Payment Id"
                            defaultValue={orderItem?.razorpay_payment_id || " "}
                            type="text"
                            onChange={handleRazorpaymentId}
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Select Payment Status
                            </InputLabel>
                            <Select
                              value={
                                status === ""
                                  ? orderItem?.replacement_payment_status
                                  : status
                              }
                              onChange={handleChange}
                              label="Select Status"
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value={"Success"}>Success</MenuItem>
                              <MenuItem value={"Failed"}>Failed</MenuItem>
                              <MenuItem value={"Refunded"}>Refunded</MenuItem>
                              <MenuItem value={"Pending"}>Pending</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Select Order Status
                            </InputLabel>
                            <Select
                              value={
                                orderStatus === ""
                                  ? orderItem?.replacement_order_status
                                  : orderStatus
                              }
                              onChange={handleOrderStatusChange}
                              label="Select Order Status"
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value={"Active"}>Active</MenuItem>
                              <MenuItem value={"Pending"}>Pending</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <hr style={{ width: "100%" }} />

                        <Typography
                          sx={{ flex: "1 1 100%", ml: 2 }}
                          variant="h6"
                          id="tableTitle"
                          component="div"
                        >
                          Courier Details
                        </Typography>
                        {/* <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="standard-basic-payment_status"
                      name="payment_status"
                      label="Payment Status"
                      value={order.payment_status}
                      type="text"
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid> */}
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-tracking_number"
                            name="tracking_number"
                            label="Tracking No."
                            onChange={handleTrackingNo}
                            defaultValue={orderItem?.tracking_number || " "}
                            type="text"
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-courier_company_name"
                            name="courier_company_name"
                            label="Courier Name"
                            onChange={handleCourierName}
                            defaultValue={
                              orderItem?.courier_company_name || " "
                            }
                            type="text"
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        </Grid>
                       <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-courier_company_name"
                        name="created At"
                        label="Created Date & Time"
                       // onChange={handleCourierName}
                        defaultValue={moment(orderItem?.createdAt).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                        type="text"
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-courier_company_name"
                        name="Updated At"
                        label="Updated Date & Time"
                       // onChange={handleCourierName}
                        defaultValue={moment(orderItem?.updatedAt).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                        type="text"
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Courier Status</InputLabel>
                <Select  value={
                            courierStatus === ""
                              ? orderItem?.courier_status
                              : courierStatus
                          } onChange={handleCourierChange} label="Select Status" inputProps={{ "aria-label": "Without label" }}>
                  <MenuItem value={"Delivered"}>Delivered</MenuItem>
                  <MenuItem value={"Dispatch"}>Dispatch</MenuItem>
                  <MenuItem value={"Returned"}>Returned</MenuItem>
                  {/* <MenuItem value={"Active"}>Active</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
                      </Grid>
                      {/* <Grid container mb={2} >
                  {order.qr_codes.length > 0 &&
                    order.qr_codes.map((ele, index) => {
                      return (
                        <React.Fragment  key={ele.qr_code_id} >
                          <Grid 
                            item
                            xs={12}
                            sm={12}
                            lg={3}
                            sx={{ ml: 2, mt: 2 }}
                          >
                            <TextField 
                              id="outlined-basic-qr_code_id"
                              label={`QR Code Id-${index + 1}`}
                              name="qr_code_id"
                              type="text"
                              value={ele.qr_code_id}
                              sx={{ width: "100%" }}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid 
                            item
                            xs={12}
                            sm={12}
                            lg={6}
                            sx={{ ml: 2, mt: 2 }}
                          >
                            <TextField  
                              id="outlined-basic-qr_code"
                              label={`QR Code-${index + 1}`}
                              name="qr_code"
                              type="text"
                              value={ele.qr_code}
                              sx={{ width: "100%" }}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        </React.Fragment>
                      );
                    })}*/}
                      {/* <Grid
                        item
                        xs={12}
                        sm={6}
                        lg={12}
                        sx={{ ml: 2, mt: 2, mb: 2 }}
                      >
                        {role === "Admin" ? 
                          <Button
                            variant="contained" // onClick={updatePaymentStatus}>
                            onClick={(event) => updatePaymentStatus(event)}
                            disabled={status === "" ? true : false}
                          >
                            Update
                          </Button>
                         : null } */}

                      {/* { role == "Admin" ?   <Button sx={{ ml: 2 }}
                      variant="contained" // onClick={updatePaymentStatus}>
                      onClick={(event) => updatePaymentStatus(event)}
                     
                    >
                      Download Invoice
                    </Button> : null } */}
                      {/* </Grid> */}
                      <Button
                        style={{ padding: "10px", margin: "18px" }}
                        type="submit"
                        variant="contained"
                        onClick={(e) => {
                          e.preventDefault(); // Prevent default form submission behavior
                          submit(); // Call your submit function
                        }}
                        
                      >
                        Update Details
                      </Button>
                      {/* <Grid item xs={12} sm={6} lg={12} sx={{ ml: 2, mt: 2 ,mb: 2 }}>
               { role == "Admin" ?   <Button
                      variant="contained" // onClick={updatePaymentStatus}>
                      onClick={(event) => updatePaymentStatus(event)}
                     
                    >
                      Download Invoice
                    </Button> : null }
                  </Grid>   */}
                    </form>
                  </Card>
                ))
            }
            
          </Paper>
        </Box>
      </Dashboard>
    </>
  );
}
