import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { mainListItems } from '../ListItems/index';
import { useEffect } from 'react';





export default function DashboardSidebar() {

  var drawerWidth = 280;

  const [role ,setRole] = useState("");  

useEffect(()=> {
setRole(JSON.parse(localStorage.getItem("adminUser")).role);
} ,[])

if(role != "Admin"){
  drawerWidth = 0;
}

const Drawer = styled(
  MuiDrawer,
  {}
)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
    },
  },
}));

  return (

    <Drawer variant="permanent">
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      />
      <Divider />
      <List component="nav">{mainListItems}</List>
    </Drawer>
  );
}
