import { React, useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Grid,
  IconButton,
  TextField,
  TablePagination,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";
import adminApiService from "../../services/admiApis.service";
import { useHistory } from "react-router-dom";
import notify from "../notification/notification";
import Dashboard from "../dashboard";
import moment from "moment";
import { log } from "@craco/craco/lib/logger";
// import dayjs, { Dayjs } from "dayjs";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: "#01579b",
    "& .MuiTableSortLabel-icon": {
      color: "#ffffff !important",
    },
  },
  tabelHeaderCell: {
    color: "#ffffff !important",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Order() {
  const history = useHistory();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("updatedAt");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderData, setOrderData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchedVal, setSearchedVal] = useState("");
  const [hide, setHide] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [role, setRole] = useState("");

  // useEffect(()=> {
  // setRole(JSON.parse(localStorage.getItem("adminUser")).role);
  // } ,[])

  var headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Order Id",
      isSort: true,
    },
    {
      id: "agent_id",
      numeric: false,
      disablePadding: false,
      label: "Agent Id",
      isSort: true,
    },
    {
      id: "referral_code",
      numeric: false,
      disablePadding: false,
      label: "Referral Code",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "phone_number",
      numeric: false,
      disablePadding: false,
      label: "Phone No.",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Order status",
      isSort: true,
    },
    {
      id: "quantity",
      numeric: false,
      disablePadding: false,
      label: "Quantity",
    },
    {
      id: "amount",
      numeric: false,
      disablePadding: false,
      label: "Payment Amount",
    },
    {
      id: "payment_status",
      numeric: false,
      disablePadding: false,
      label: "Payment Status",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created Date",
      isSort: true,
    },
    {
      id: "updatedAt",
      numeric: false,
      disablePadding: false,
      label: "Updated Date",
      isSort: true,
    },

    // {
    //   id: "courier_company_name",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Courier Name",
    // },
    // {
    //   id: "tracking_number",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Tracking No.",
    // },

    // {

    //   id: "edit",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Update Order Status",
    // },

    //  {
    //     id: "assign-qr-code",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Assign QR Code",
    //   },
  ];

  headCells =
    role != "Admin"
      ? headCells.filter((item) => !["edit"].includes(item.id))
      : headCells;

  function EnhancedTableHead(props) {
    const classes = useStyles();
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead className={classes.tableHeader}>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.isSort ? (
                <>
                  <TableSortLabel
                    className={classes.tabelHeaderCell}
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </>
              ) : (
                <span className="table-headCell" style={{ color: "#fff" }}>
                  {headCell.label}
                </span>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  useEffect(() => {
    setRole(JSON.parse(localStorage.getItem("adminUser")).role);
  }, []);

  useEffect(() => {
    // const payload = {
    //   phone_number :"hbhj"

    // };

    async function fetchData() {
      const res = await adminApiService.getAllAgentOrders();
      if (res.success) {
        setOrderData(res.response);
        // console.log(res);
        if (role == "Admin") {
          // console.log(role);
          setFilterData(res.response);
        }
      } else {
        setOrderData([]);
        setFilterData([]);
      }
    }

    fetchData().catch(console.error);
  }, [role]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function exportExcel() {
    let orderList = JSON.parse(JSON.stringify(filterData));
    // console.log(orderList)
    orderList = orderList.map((item) => {
      let data = {
        id: item.id,
        name: item.name,
        email: item.email,
        amount: item.amount,
        phone_number: item.phone_number,
        quantity: item.quantity,
        status: item.status,
        payment_status:item.payment_status,
        createdAt: moment(item.createdAt).format("DD/MM/YYYY"),
        agent_id: item.agent_id,
        referral_code: item.referral_code,
        courier_company_name: item.courier_company_name,
        tracking_number: item.tracking_number,
        gender: item.gender,
        blood_group: item.blood_group,
        emergency_contact1: item.emergency_contact1,
        emergency_contact2: item.emergency_contact2,
        emergency_contact3: item.emergency_contact3,
        emergency_contact4: item.emergency_contact4,
        emergency_contact5: item.emergency_contact5,
        relation1: item.relation1,
        relation2: item.relation2,
        relation3: item.relation3,
        relation4: item.relation4,
        relation5: item.relation5,
        building_no:item.building_no,
        building_name:item.building_name,
        street:item.street,
        area:item.area,
        district:item.district,
        state:item.state,
        pincode:item.pincode,
        address:item.address,
        razorpay_payment_id:item.razorpay_payment_id,
        vehicle_type: item.vechile_type,
        vechile_no:item.vechile_no,
        chassis_number:item.chassis_number,
      };
      return data;
    });
    // console.log(orderList);

    const res = await adminApiService.getExcelData(orderList);
    // console.log(res);
    if (res.success == true) {
      const link = document.createElement("a");
      link.href = res.response.excel_url;
      link.setAttribute("download", true);
      // link.setAttribute("download", `${Math.floor(Math.random() * 6) + 1}.zip`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  }

  const viewOrder = (event, data) => {
    history.push(`/viewOrder/${data.id}`);
  };

  const handleUpdateOrder = (event, updateOrder) => {
    event.stopPropagation();
    history.push(`/updateOrder/${updateOrder.id}`);
  };

  const handleAssignQRCode = (event, updateOrder) => {
    event.stopPropagation();
    history.push("/assignQRCode", {
      quantity: updateOrder.quantity,
      id: updateOrder.id,
    });
  };

  const handleSearch = async (event) => {
    setPage(0);
    setSearchedVal(event.target.value);
    // console.log(searchedVal);
    if (role == "Admin") {
      const filterData = orderData.filter((ele) => {
        return (
          ele.id?.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()) ||
          ele.name?.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()) ||
          ele.email?.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()) ||  
          ele.phone_number?.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()) ||    
          ele.referral_code?.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()) ||
          ele.agent_id?.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()) 
            
        );
      });
      setFilterData(filterData);
    } else {
      // const filterData = orderData.filter((ele) => {

      //   return (

      //     ele.phone_number.toString().toLowerCase().includes(event.target.value.toString().toLowerCase())
      //   );
      // });

      const res = await adminApiService.getAllAgentOrders(
        //latest list agent orders
        `?phone_number=${event.target.value}`
      );
      if (res.success) {
        setOrderData(res.response);
        // console.log(res);
      } else {
        // setOrderData([]);
        // setFilterData([]);
        setFilterData([]);
        return false;
      }

      //  if ( event.target.value.length == 10 ){

      setFilterData(event.target.value == "" ? [] : res.response);

      //  }

      //  if(filterData.length == 0){

      //   alert("No resuls found");
      // }
    }
  };

  const handleStartDateChange = (event) => {
    const startDateValue = event.target.value;
    setStartDate(startDateValue);
    // console.log(startDate);
  };

  const handleEndDateChange = (event) => {
    const endDateValue = event.target.value;
    setEndDate(endDateValue);
    //  console.log(endDate);
  };

  const fetchDateData = async () => {
    try {
      // Send API request only when the user hits the "Apply" button
      const res = await adminApiService.getAllAgentOrdersDatewise(
        //latest list agent orders
        startDate,
        endDate
      );
      if (res.success === true) {
        setFilterData(res.response);
      } else {
        // setOrderData([]);
        // setFilterData([]);
        notify.error(res.message);
        setFilterData([]);
        return false;
      }

      //  if ( event.target.value.length == 10 ){

      //setFilterData(event.target.value == "" ? [] : res.response);
      // Process the API response here
      //  console.log("date",res)
    } catch (error) {
      // Handle errors
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderData.length) : 0;

  return (
    <>
      <Dashboard>
        {orderData && orderData.length > 0 ? (
          <Box className="content-wrapper" mt={2}>
            <Paper sx={{ mr: 1, ml: 1, mb: 2 }}>
              <Toolbar
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  mb: 2,
                }}
              >
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Agent Order List
                </Typography>

                <label htmlFor="search">Search</label>
                {role == "Admin" ? (
                  <input
                    className="search-input-box"
                    id="search"
                    type="text"
                    onChange={handleSearch}
                    placeholder="Search by Id, Name, Email, Phone no,agent_id,referral_code"
                  />
                ) : null}
                {role != "Admin" ? (
                  <input
                    className="search-input-box"
                    id="search"
                    type="text"
                    maxLength="10"
                    onChange={handleSearch}
                    placeholder="Search by Phone Number"
                  />
                ) : null}

                {role == "Admin" ? (
                  <Button
                    size="small"
                    style={{
                      background: " rgb(1 87 155)",
                      width: "200px",
                      height: "35px",
                      color: " white",
                      left: "2px",
                    }}
                    onClick={exportExcel}
                  > 
                    Export To Excel
                  </Button>
                ) : null}
              </Toolbar>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  mt: "2",
                  mb: "2",
                  padding: "5px",
                }}
              >
                <Grid item xs={12} sm={6} lg={3} sx={{ ml: 0, mt: 2 }}>
                  <TextField
                    id="standard-basic-v_insu_valid_to"
                    name="v_insu_valid_to"
                    label="Start Date"
                    size="small"
                    onChange={handleStartDateChange}
                    value={startDate}
                    type="date"
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true, // Shrink the label when a date is selected
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3} sx={{ ml: 1, mt: 2 }}>
                  <TextField
                    id="standard-basic-v_insu_valid_to"
                    name="v_insu_valid_to"
                    label="End Date"
                    size="small"
                    value={endDate}
                    onChange={handleEndDateChange}
                    type="date"
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true, // Shrink the label when a date is selected
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3} sx={{ ml: 1, mt: 2 }}>
                  <Button
                    size="small"
                    style={{
                      background: " rgb(1 87 155)",
                      width: "20px",
                      height: "35px",
                      color: " white",
                    }}
                    onClick={fetchDateData}
                  >
                    apply
                  </Button>
                </Grid>
              </div>
              <TableContainer>
                <Table
                  id="table-to-xls"
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={() => {}}
                    onRequestSort={handleRequestSort}
                    rowCount={orderData.length}
                  />
                  <TableBody>
                    {stableSort(filterData, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            style={{ cursor: "pointer" }}
                            tabIndex={-1}
                            key={row.id}
                            onClick={(event) => viewOrder(event, row)}
                          >
                            <TableCell align="left">{row.id}</TableCell>
                            <TableCell aligh="left">{row.agent_id}</TableCell>
                            <TableCell aligh="left">
                              {row.referral_code}
                            </TableCell>
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="left">
                              {row.phone_number}
                            </TableCell>
                            <TableCell align="left">{row.status}</TableCell>
                            <TableCell align="left">{row.quantity}</TableCell>
                            <TableCell align="left">{row.amount}</TableCell>

                            <TableCell align="left">
                              {row.payment_status}
                            </TableCell>
                            <TableCell align="left">
                              {moment(row.createdAt).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {moment(row.updatedAt).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </TableCell>
                            {/* <TableCell align="left">{row.courier_company_name}</TableCell>
                            <TableCell align="left">{row.tracking_number}</TableCell> */}

                            {/* { 
                           role=="Admin" ? 
                            <TableCell align="left">
                              <IconButton onClick={(event) => handleUpdateOrder(event, row)} disabled={row.status === "Pending" ? true : false}>
                                <EditIcon />
                              </IconButton>
                            </TableCell> : null } */}
                            {/* <TableCell align="left">
                            {role =="Admin" ?     <IconButton onClick={(event) => handleAssignQRCode(event, row)} disabled={row.status === "Active" ? false : true} >
                                <EditIcon />
                              </IconButton>: null }  
                     {role !="Admin" ?        <IconButton onClick={(event) => handleAssignQRCode(event, row)} disabled={(row.qr_status === "Not Available"  ? true : false ) || ( row.status === "Active" ? false : true ) } >
                                <EditIcon />
                              </IconButton> : null }  
                 
                            </TableCell> */}
                          </TableRow>
                        );
                      })}

                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                {filterData.length == 0 && searchedVal != "" && (
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "23px",
                      paddingTop: "30px",
                    }}
                  >
                    {" "}
                    No Results Found
                  </span>
                )}
              </TableContainer>{" "}
              :
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filterData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        ) : null}
      </Dashboard>
    </>
  );
}
