import React from 'react';
import './index.css';

function Error404() {
  return (
    <div className="container">
      <h1>Page not found</h1>
    </div>
  );
}

export default Error404;
