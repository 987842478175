import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Toolbar from "@mui/material/Toolbar";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import {Multiselect} from "multiselect-react-dropdown";
import Autocomplete from '@mui/material/Autocomplete';
import Dashboard from "../dashboard";
import adminApiService from "../../services/admiApis.service";
import notify from "../notification/notification";

import { useHistory, useParams, useLocation } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedQRCode, theme) {
  return {
    fontWeight: selectedQRCode.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export default function AssignReplacementQRCode() {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const { state } = location;
  const theme = useTheme();
  const [qrList, setQRList] = useState([]);
  const [selectedQRCode, setSelectedQRCode] = useState(null);
  const [replacementQRData, setReplacementQRData] = useState("");
  //  {label: 'React',      value: 'react'},
  //   {label: 'JavaScript', value: 'js'   },
  //   {label: 'TypeScript', value: 'ts'   }
  // ]);

  // console.log("state", state);

  useEffect(async () => {
    try {
      const res = await adminApiService.getInactiveQRCodes({ qr_code_id:replacementQRData,status: null });
      if (res.success) {
     //   console.log(res)
        let data = res.response.map((item)=>{
          item.label = item.qr_code_id.toString();
          return item;
        })
        // console.log(res.response);
        setQRList(data);
      } else {
        setQRList([]);
      }
    } catch (err) {
       console.log(err);
    }
  }, []);

  const handleChange = async (event,value) => {

    // console.log(event,value);
    // const {
    //   target: { value },
    // } = event;
    // let data = event.map(item => item.qr_code_id);
   //  console.log(event);


    setSelectedQRCode(event.target.value);
   setReplacementQRData(event.target.value)
 // console.log(event.target.value);
  if(event.target.value==""){
    return false;
  }
  try {
    const res = await adminApiService.getInactiveQRCodes({ qr_code_id:event.target.value,status: null });
    if (res.success) {
    //  console.log(res)
      let data = res.response.map((item)=>{
        item.label = item.qr_code_id.toString();
        return item;
      })
      // console.log(res.response);
      setQRList(data);
    } else {
      setQRList([]);
    }
  } catch (err) {
     console.log(err);
  }
    // setSelectedQRCode(typeof value === "string" ? value.split(",") : value);
  };

  const updateReplacementOrder = async (event) => {
    if (!selectedQRCode) {
      alert("Please Assign QR Code");
      return;
    }

    const data = {
        replacement_order_id: state.id,
        new_qr_code_id: Number(selectedQRCode),
    };

    // if (data.qr_code_id.length > state.quantity) {
    //   alert(`Maximum assign QR Code to be ${state.quantity}`);
    //   return;
    // }

    const res = await adminApiService.addQRIntoReplacementOrder(data);
    if (res.success) {
      notify.sucess("QR Code Assigned Successfully");
      history.push("/replacementOrder");
    } else {
      notify.error(res.message);
    }
  };

  const Sdata =[
  
    {country : '51',id:1},
    {country : '36',id:1},
    {country : '22',id:1},
    {country : '24',id:1},{country : '85',id:1},
  ]
  

  const [role ,setRole] = useState("");  

  useEffect(()=> {
  setRole(JSON.parse(localStorage.getItem("adminUser")).role);
  } ,[])

  const [options]=useState(qrList);
  const [enterQr, setEnterQr] = useState("");

  const handleEnterQr = (event) => {
    setEnterQr(event.target.value.trim());
  };

  // const qrCodes = () => [ qrList];

  
  const updateQrOrder = async (event) => {
   
    if (!enterQr) {
      alert("Please enter the QR Code ID");
      return;
    }
    var regex = /^[0-9\s]*$/;
   var isValid = regex.test(enterQr);

   if(!isValid){
    alert("Enter only numbers");
    return false;
   }
   var qr = enterQr.split(" ").map(item => Number(item));

    const data = {
      replacement_order_id: state.id,
        new_qr_code_id: qr,
    };
    // console.log(state.quantity);
    if (data.qr_code_id.length > state.quantity) {
      alert(`Maximum assign QR Code to be ${state.quantity}`);
      return;
    }

    const res = await adminApiService.addQRIntoOrder(data);
    if (res.success) {
      notify.sucess("QR Code Assign Successfully");
      history.push("/order");
    } else {
      notify.error(res.message);
    }
  };



  return (
    <Dashboard>
      <div className="update-order-container">
        <Card sx={{ pb: 16 }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
              Assign Replacement Order QR Code
            </Typography>
          </Toolbar>
          <Grid container spacing={3} sx={{ pl: 2 }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-multiple-chip-label">QRCode</InputLabel> */}
                <h3 style={{fontWeight:"400"}}>QR Code</h3>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  
                  options={qrList}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField  onChange ={handleChange} {...params} label="QR Codes"  />}
                  // renderInput={(params) => <TextField   {...params} label="QR Codes"  />}

                />




{/* 
                  <Select placeholder="Select QR Code"  onSelect={handleChange}  value={selectedQRCode} options={qrList}>
                    
                    
                    </Select>  */}





                {/* <Select  type="text"
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  
                  multiple  ////// displayValue="qr_code_id" onRemove={handleChange}////////
                  value={selectedQRCode}
                  onChange={handleChange}  ///// value={selectedQRCode}   onSelect={handleChange}  onChange={handleEnterQr}////
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  
                  MenuProps={MenuProps}
                >
                  {qrList.map((ele) => (
                    <MenuItem key={ele.qr_code_id} value={ele.qr_code_id} style={getStyles(ele.qr_code_id, selectedQRCode, theme)}>
                      {ele.qr_code_id}
                    </MenuItem>
                  ))}
                </Select> */}


{/* { role != "Admin" ?   
              //  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                  <TextField
                    id="standard-basic-vechile_type"
                    label="Enter QR Code ID (Use space if entering multiple values)"
                    name="QR Code"
                    type="text"
                    InputProps={{ min: "1", max: "12" }}
                    onChange={handleEnterQr}
                    style={{width:"100%",maxWidth:"100%"}}
                  />
                // </Grid>
                 : null } */}

              </FormControl>
            </Grid>
           

        { role == "Admin" ?          <Grid item xs={12} sm={6} lg={12}>
              <Button variant="contained" onClick={updateReplacementOrder}>
                Assign
              </Button>
            </Grid> : null}
            
            {/* { role != "Admin" ?    
               <Grid item xs={12} sm={6} lg={12}>
              <Button variant="contained" onClick={updateQrOrder}>
                Assign
              </Button>
            </Grid> : null } */}

          </Grid>
        </Card>
      </div>
    </Dashboard>
  );
}
