import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BarChartIcon from "@mui/icons-material/BarChart";
import PhoneIcon from "@material-ui/icons/Phone";
import SmsIcon from "@material-ui/icons/Sms";
import ListIcon from "@material-ui/icons/List";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import TocIcon from '@mui/icons-material/Toc';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { Link } from "react-router-dom";
import CarRepairIcon from '@mui/icons-material/CarRepair';

export const mainListItems = (
  <React.Fragment>
    <ListItemButton component={Link} to="/">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard"></ListItemText>
    </ListItemButton>

    <ListItemButton component={Link} to="/order">
      <ListItemIcon>
        <ListIcon />
      </ListItemIcon>
      <ListItemText primary="Agent Order List" />
    </ListItemButton>
    <ListItemButton component={Link} to="/customerOrder">
      <ListItemIcon>
        <ListIcon />
      </ListItemIcon>
      <ListItemText primary="Customer Order List" />
    </ListItemButton>

    <ListItemButton component={Link} to="/replacementOrder">
      <ListItemIcon>
        <TocIcon />
      </ListItemIcon>
      <ListItemText primary="Replacement Order List" />
    </ListItemButton>

    {/* 
    <ListItemButton component={Link} to="/createOrder">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Create Order" />
    </ListItemButton> */}

    <ListItemButton component={Link} to="/referralCodes">
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Referral Code" />
    </ListItemButton>

    <ListItemButton component={Link} to="/addReferral">
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Add Referral" />
    </ListItemButton>

    <ListItemButton component={Link} to="/addVehicle">
      <ListItemIcon>
        <LocalShippingIcon />
      </ListItemIcon>
      <ListItemText primary="Add Vehicle" />
    </ListItemButton>

    <ListItemButton component={Link} to="/qrList">
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="QR List" />
    </ListItemButton>

    <ListItemButton component={Link} to="/smsHistory">
      <ListItemIcon>
        <SmsIcon />
      </ListItemIcon>
      <ListItemText primary="SMS History" />
    </ListItemButton>

    <ListItemButton component={Link} to="/smsContent">
      <ListItemIcon>
        <SmsIcon />
      </ListItemIcon>
      <ListItemText primary="SMS Message" />
    </ListItemButton>

    <ListItemButton component={Link} to="/callHistory">
      <ListItemIcon>
        <PhoneIcon />
      </ListItemIcon>
      <ListItemText primary="Owner Call History" />
    </ListItemButton>

    <ListItemButton component={Link} to="/emergencyCallHistory">
      <ListItemIcon>
        <PhoneIcon />
      </ListItemIcon>
      <ListItemText primary="Emergency Call History" />
    </ListItemButton>

  <ListItemButton component={Link} to="/vehicleType">
      <ListItemIcon>
        <LocalShippingIcon />
      </ListItemIcon>
      <ListItemText primary="Vehicle Type" />
    </ListItemButton> 

    <ListItemButton component={Link} to="/fetchVehicleDetails">
      <ListItemIcon>
        <CarRepairIcon />
      </ListItemIcon>
      <ListItemText primary="Fetch Vehicle Details" />
    </ListItemButton>

    
    <ListItemButton component={Link} to="/customerDetailsList">
      <ListItemIcon>
      <TocIcon />
      </ListItemIcon>
      <ListItemText primary="Customer Details List" />
    </ListItemButton>

    <ListItemButton component={Link} to="/uploadCsv">
      <ListItemIcon>
      <UploadFileIcon />
      </ListItemIcon>
      <ListItemText primary="upload CSV" />
    </ListItemButton>
  </React.Fragment> 
);
