import adminApiService from '../services/admiApis.service';

export async function loginUser(dispatch, loginPayload) {
  try {
    dispatch({ type: 'REQUEST_LOGIN' });
    let response = await adminApiService.authenticateUser(loginPayload);
   // console.log(response);
    if (response.success) {
      const data = response.admin;
    //  console.log(response);
      dispatch({ type: 'LOGIN_SUCCESS', payload: data });
      localStorage.setItem('adminUser', JSON.stringify(data));
     
      localStorage.setItem('token', response.token);
      localStorage.setItem('isloggedIn', true);
      return data;
    }
//

    dispatch({ type: 'LOGIN_ERROR', error: response.data.errors[0] });
    return;
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error });
    alert(error.error);
  }
}

export async function logout(dispatch) {
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('adminUser');
  localStorage.removeItem('token');
  localStorage.removeItem('isloggedIn');
}
