import { React, useState, useEffect } from "react";
import adminApiService from "../../services/admiApis.service";
import { Box, Toolbar, Typography, Card, Grid, TextField, Paper } from "@mui/material";
import { useParams } from "react-router-dom";

import Dashboard from "../dashboard";

import moment from "moment";

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
        View QR Details
      </Typography>
    </Toolbar>
  );
};

export default function QRDetails() {
  const [qrDetail, setQRDetail] = useState({});
  const params = useParams();

  useEffect(() => {
    async function fetchData() {
      const res = await adminApiService.viewQRCodeDetails(params.qrCode);
      if (res.success) {
        setQRDetail({ ...res.response });
      }
    }

    fetchData().catch(console.error);
  }, []);

  return (
    <>
      <Dashboard>
        <Box className="content-wrapper" sx={{ mb: 2, mt: 2 }}>
          <Paper sx={{ mr: 1, ml: 1, mb: 2 }}>
            <EnhancedTableToolbar />
            {Object.keys(qrDetail).length > 0 && (
              <Card className="mt-2">
                <Grid container mb={2}>
                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="standard-basic-amount-name"
                      name="name"
                      label="Name"
                      type="text"
                      value={qrDetail.name}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="outlined-basic-blood_group"
                      label="Blood Group"
                      name="blood_group"
                      type="text"
                      value={qrDetail.blood_group}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="outlined-basic-vechile_no"
                      label="Vechile No"
                      name="vechile_no"
                      type="text"
                      value={qrDetail.vechile_no}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="outlined-basic-chassis_number"
                      label="chassis No"
                      name="chassis_number"
                      type="text"
                      value={qrDetail.chassis_number}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="outlined-basic-emergency_contact1"
                      label="Emergency Contact 1"
                      name="emergency_contact1"
                      type="tel"
                      value={qrDetail.emergency_contact1}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="outlined-basic-emergency_contact2"
                      label="Emergency Contact 2"
                      name="emergency_contact2"
                      type="tel"
                      value={qrDetail.emergency_contact2}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="outlined-basic-emergency_contact3"
                      label="Emergency Contact 3"
                      name="emergency_contact3"
                      type="tel"
                      value={qrDetail.emergency_contact3}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="outlined-basic-emergency_contact4"
                      label="Emergency Contact 4"
                      name="emergency_contact4"
                      type="tel"
                      value={qrDetail.emergency_contact4}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="outlined-basic-emergency_contact5"
                      label="Emergency Contact 5"
                      name="emergency_contact5"
                      type="tel"
                      value={qrDetail.emergency_contact5}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                 
                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="outlined-basic-relation1"
                      label="relation 1"
                      name="relation1"
                      type="text"
                      value={qrDetail.relation1}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="outlined-basic-relation2"
                      label="relation 2"
                      name="relation2"
                      type="text"
                      value={qrDetail.relation2}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="outlined-basic-relation3"
                      label="relation 3"
                      name="relation3"
                      type="text"
                      value={qrDetail.relation3}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="outlined-basic-relation4"
                      label="relation 4"
                      name="relation4"
                      type="text"
                      value={qrDetail.relation4}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="outlined-basic-relation5"
                      label="relation 5"
                      name="relation5"
                      type="text"
                      value={qrDetail.relation5}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="standard-basic-gender"
                      label="Gender"
                      name="gender"
                      type="text"
                      value={qrDetail.gender}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="standard-basic-quantity-mobile_no"
                      name="mobile_no"
                      label="mobile_no"
                      type="tel"
                      value={qrDetail.mobile_no}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="standard-basic-quantity-qr_code"
                      name="qr_code"
                      label="QR Code"
                      type="text"
                      value={qrDetail.qr_code}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="standard-basic-quantity-createdAt"
                      name="createdAt"
                      label="Created At"
                      type="text"
                      value={moment(qrDetail.createdAt).format("DD/MM/YYYY")}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={12}>
                    <Typography sx={{ flex: "1 1 100%", ml: 2, mt: 2 }} variant="h6" id="tableTitle" component="div">
                      QR Image
                    </Typography>
                    <img src={qrDetail.path} alt="qrCode"></img>
                  </Grid>

                  {qrDetail.scan_qr_location.length > 0 && (
                    <Grid item xs={12} sm={6} lg={12} sx={{ ml: 2, mt: 2 }}>
                      <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
                        QR Scan Details
                      </Typography>
                    </Grid>
                  )}

                  {qrDetail.scan_qr_location.map((ele, index) => {
                    return (
                      <>
                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-quantity-createdAt"
                            name="createdAt"
                            label="Created At"
                            type="text"
                            value={moment(ele.createdAt).format("DD/MM/YYYY hh:mm:ss")}
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-quantity-latitude"
                            name="latitude"
                            label="latitude"
                            type="text"
                            value={ele.latitude}
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                          <TextField
                            id="standard-basic-quantity-longitude"
                            name="longitude"
                            label="longitude"
                            type="text"
                            value={ele.longitude}
                            sx={{ width: "100%" }}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </Card>
            )}
          </Paper>
        </Box>
      </Dashboard>
    </>
  );
}
