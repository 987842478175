// const baseUrl = "http://3.111.76.3/v1/";
const otpUrl = "https://hdelight.in/api/v2/"; //hd api for otp verification
const baseUrl = "https://admin.rq.highwaydelite.com/v1/"; //prod url

export const getWithoutToken = (url) => {
  return fetch(`${baseUrl}${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      "Accept-Encoding": "gzip",
      "Strict-Transport-Security": "max-age = 63072000;",
    },
  })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw await res.json();
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const postWithoutToken = (url, body) => {
  return fetch(`${baseUrl}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      "Strict-Transport-Security": "max-age = 63072000;",
    },
    body: JSON.stringify(body),
  })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw await res.json();
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const putWithoutToken = (url, body, param) => {
  return fetch(`${baseUrl}${url}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      "Strict-Transport-Security": "max-age = 63072000;",
    },
    body: JSON.stringify(body),
  })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw await res.json();
      }
    })
    .catch((err) => {
      return err;
    });
};

export const get = (url) => {
  const accessToken = localStorage.getItem("token");
  return fetch(`${baseUrl}${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${accessToken}`,
      "Strict-Transport-Security": "max-age = 63072000;",
    },
  })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else if (res.status === 401 || res.status === 403) {
        localStorage.clear();
        document.location.href = "/login";
      } else {
        throw await res.json();
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const post = (url, body) => {
  const accessToken = localStorage.getItem("token");
  return fetch(`${baseUrl}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${accessToken}`,
      "Strict-Transport-Security": "max-age = 63072000;",
    },
    body: JSON.stringify(body),
  })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw await res.json();
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const postRequestToSendOTP = (url, body) => {
  const accessToken = localStorage.getItem("token");
  return fetch(`${otpUrl}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${accessToken}`,
      "Strict-Transport-Security": "max-age = 63072000;",
    },
    body: JSON.stringify(body),
  })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        //  throw await res;
        throw await res.json();
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const put = (url, body, param) => {
  let accessToken = localStorage.getItem("token");
  return fetch(`${baseUrl}${url}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${accessToken}`,
      "Strict-Transport-Security": "max-age = 63072000;",
    },
    body: JSON.stringify(body),
  })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw await res.json();
      }
    })
    .catch((err) => {
      return err;
    });
};

export const getDownloadTemplate = (url) => {
  let accessToken = localStorage.getItem("accessToken");
  return fetch(`${baseUrl}${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${accessToken}`,
      "Strict-Transport-Security": "max-age = 63072000;",
    },
  })
    .then(async (res) => {
      if (res.ok) {
        return res.blob();
      } else {
        throw await res.json();
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteWithToken = (url, body) => {
  let accessToken = localStorage.getItem("token");
  // console.log("Request URL:", `${baseUrl}${url}`);
  // console.log("Request Data:", data);
  return fetch(`${baseUrl}${url}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${accessToken}`,
      "Strict-Transport-Security": "max-age=63072000;",
    },
    body: JSON.stringify(body),
  })
    .then(async (res) => {
      console.log("Response Status:", res.status);
      if (res.ok) {
        return res.json();
      } else {
        throw await res.json();
      }
    })
    .catch((err) => {
      console.error("Request Error:", err);
      return err;
    });
};

export const deleteWithoutToken = (url, param) => {
  return fetch(`${baseUrl}${url}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      "Strict-Transport-Security": "max-age=63072000;",
    },
  })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw await res.json();
      }
    })
    .catch((err) => {
      return err;
    });
};
