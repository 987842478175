import React, { useState, useEffect } from "react";
import adminApiService from "../../services/admiApis.service";
import {
  Box,
  Toolbar,
  Typography,
  Card,
  Grid,
  TextField,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import { useParams } from "react-router-dom";
import notify from "../notification/notification";
import moment from "moment";
// import {  useHistory } from 'react-router';

import Dashboard from "../dashboard";

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Update Customer Details
      </Typography>
    </Toolbar>
  );
};

export default function UpdateCustomerDetails() {
  const [detail, setDeatil] = useState({});
  const [storeQr, setStoreQr] = useState(null);
  const [relative1, setRelative1] = useState("");
  const [relative2, setRelative2] = useState("");
  const [relative3, setRelative3] = useState("");
  const [relative4, setRelative4] = useState("");
  const [relative5, setRelative5] = useState("");
  const [relationOptions, setRelationOptions] = useState([]);
  const [formfield, setFormfield] = useState({
    name: "",
    email: "",
    mobile_no: "",
    vechile_no: "",
    vechile_type: "",
    emergency_contact: "",
    street: "",
    district: "",
    state: "",
    area: "",
    building_name: "",
    building_no: "",
    pincode: "",
    gender: "",
    // relation: "",
    blood_group: "",
    emergency_contact1: "",
    emergency_contact2: "",
    emergency_contact3: "",
    emergency_contact4: "",
    emergency_contact5: "",
    relation1: "",
    relation2: "",
    relation3: "",
    relation4: "",
    relation5: "",
    chassis_number: "",
  });

  const params = useParams();
  // const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const res = await adminApiService.viewCustomerDetails(params.id);
      if (res.success) {
        setDeatil({ ...res.response });
        setStoreQr(res.response.qr_code_id);
      }
    }

    fetchData().catch(console.error);
  }, []);

  const [gender, setGender] = useState("");
  const [relative, setRelative] = useState("");
  const [blood, setBlood] = useState("");
  //  console.log(detail.vechile_type);

  const handleGenderChange = (e) => {
    const selectedGender = e.target.value;
    setGender(selectedGender);
    // setGender(event.target.value);
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      gender: e.target.value,
    }));
  };

  const [role, setRole] = useState("");

  useEffect(() => {
    setRole(JSON.parse(localStorage.getItem("adminUser")).role);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    //  console.log(formfield);
  };

  const handleName = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      name: e.target.value,
    }));
  };
  const handleBuildingName = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      building_name: e.target.value,
    }));
  };
  const handleBuildingNo = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      building_no: e.target.value,
    }));
  };
  const handleEmail = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      email: e.target.value,
    }));
  };
  const handlePhone = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      mobile_no: e.target.value,
    }));
  };
  const handleEmergency = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      emergency_contact: e.target.value,
    }));
  };
  const handleStreet = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      street: e.target.value,
    }));
  };
  const handleState = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      state: e.target.value,
    }));
  };
  const handleArea = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      area: e.target.value,
    }));
  };
  const handleDistrict = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      district: e.target.value,
    }));
  };
  const handlePincode = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      pincode: e.target.value,
    }));
  };

  const handleVehicleCoName = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      vechile_insurance_company_name: e.target.value,
    }));
  };
  const handleVehicleCoNo = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      vechile_insurance_no: e.target.value,
    }));
  };
  const handleVehicleFrom = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      v_insu_valid_from: e.target.value,
    }));
  };
  const handleVehicleTo = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      v_insu_valid_to: e.target.value,
    }));
  };
  const handleHealthCoName = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      health_insurance_company_name: e.target.value,
    }));
  };
  const handleHealthCoNo = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      health_insurance_no: e.target.value,
    }));
  };
  const handleHealthCoFrom = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      h_insu_valid_from: e.target.value,
    }));
  };
  const handleHealthCoTo = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      h_insu_valid_to: e.target.value,
    }));
  };

  const handleBlood = (e) => {
    const selectedBlood = e.target.value;
    setBlood(selectedBlood);
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      blood_group: selectedBlood,
    }));
  };

  const handleRelativeChange = (relativeNumber, e) => {
    const selectedRelative = e.target.value;
    // Assuming you have state variables like relative1, relative2, relative3
    // Update the corresponding relative state
    switch (relativeNumber) {
      case 1:
        setRelative1(selectedRelative);
        break;
      case 2:
        setRelative2(selectedRelative);
        break;
      case 3:
        setRelative3(selectedRelative);
        break;
      case 4:
        setRelative4(selectedRelative);
        break;
      case 5:
        setRelative5(selectedRelative);
        break;
      default:
        break;
    }

    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      [`relation${relativeNumber}`]: e.target.value,
    }));
  };

  const handleEmergencyContact = (contactNumber, e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      [`emergency_contact${contactNumber}`]: e.target.value,
    }));
  };

  const handleChasisno = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      chassis_number: e.target.value,
    }));
  };

  useEffect(() => {
    async function fetchRelationData() {
      const res = await adminApiService.getRelation();
      const relationTypes = res.response.map((item) => item.relation);
      //  console.log(relationTypes);
      setRelationOptions(relationTypes);
    }
    fetchRelationData().catch(console.error);
  }, []);

  const submit = async () => {
    const data = {
      name: formfield.name,
      email: formfield.email,
      mobile_no: formfield.mobile_no,
      vechile_no: formfield.vechile_no,
      vechile_type: formfield.vechile_type,
      emergency_contact: formfield.emergency_contact,
      street: formfield.street,
      district: formfield.district,
      state: formfield.state,
      area: formfield.area,
      building_name: formfield.building_name,
      building_no: formfield.building_no,
      pincode: formfield.pincode,
      gender: formfield.gender,
      relation: formfield.relation,
      blood_group: formfield.blood_group,
      vechile_insurance_company_name: formfield.vechile_insurance_company_name,
      vechile_insurance_no: formfield.vechile_insurance_no,
      v_insu_valid_from: formfield.v_insu_valid_from,
      v_insu_valid_to: formfield.v_insu_valid_to,
      health_insurance_company_name: formfield.health_insurance_company_name,
      health_insurance_no: formfield.health_insurance_no,
      h_insu_valid_from: formfield.h_insu_valid_from,
      h_insu_valid_to: formfield.h_insu_valid_to,
      chassis_number: formfield.chassis_number,
      relation1: formfield.relation1,
      relation2: formfield.relation2,
      relation3: formfield.relation3,
      relation4: formfield.relation4,
      relation5: formfield.relation5,
      emergency_contact1: formfield.emergency_contact1,
      emergency_contact2: formfield.emergency_contact2,
      emergency_contact3: formfield.emergency_contact3,
      emergency_contact4: formfield.emergency_contact4,
      emergency_contact5: formfield.emergency_contact5,
    };
    const res = await adminApiService.updateCustomerDetails(storeQr, data);
    if (res.success) {
      notify.sucess("Details updated successfully");
    } else {
      notify.error("update failed try again");
    }
  };

  return (
    <>
      <Dashboard>
        <Box className="content-wrapper" sx={{ mb: 2, mt: 2 }}>
          <Paper sx={{ mr: 1, ml: 1, mb: 2 }}>
            <EnhancedTableToolbar />
            {Object.keys(detail).length > 0 && (
              //  this.gender=detail.gender,
              <Card className="mt-2">
                <form onSubmit={handleSubmit}>
                  <Grid container mb={2}>
                    <Typography
                      sx={{ flex: "1 1 100%", ml: 2 }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      Personal Details
                    </Typography>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="Name"
                        name="name"
                        type="text"
                        onChange={handleName}
                        defaultValue={detail?.name || ""}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="Phone Numbers"
                        name="mobile_no"
                        type="tel"
                        onChange={handlePhone}
                        defaultValue={detail?.mobile_no || ""}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic"
                        label="Email"
                        name="email"
                        type="email"
                        onChange={handleEmail}
                        defaultValue={detail?.email || ""}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-building-number"
                        name="building_no"
                        label="Building/Flat Number"
                        onChange={handleBuildingNo}
                        defaultValue={detail?.building_no || ""}
                        type="text"
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-building-name"
                        name="building_name"
                        label="Building/Flat Name"
                        onChange={handleBuildingName}
                        defaultValue={detail?.building_name || ""}
                        type="text"
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-street"
                        name="street"
                        label="Address Line 1"
                        // value={detail.detail && detail.detail.phone_number ? detail.detail.phone_number : " "}
                        onChange={handleStreet}
                        defaultValue={detail?.street || ""}
                        type="text"
                        sx={{ width: "100%" }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-area"
                        name="area"
                        label="Address Line 2"
                        // value={detail.detail && detail.detail.phone_number ? detail.detail.phone_number : " "}
                        onChange={handleArea}
                        defaultValue={detail?.area || ""}
                        type="text"
                        sx={{ width: "100%" }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-district"
                        name="district"
                        label="District/City"
                        // value={detail.detail && detail.detail.phone_number ? detail.detail.phone_number : " "}
                        onChange={handleDistrict}
                        defaultValue={detail?.district || ""}
                        type="text"
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-state"
                        name="State"
                        label="State"
                        onChange={handleState}
                        defaultValue={detail?.state || ""}
                        type="text"
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-pincode"
                        name="pincode"
                        label="Pincode"
                        // value={detail.detail && detail.detail.phone_number ? detail.detail.phone_number : " "}
                        onChange={handlePincode}
                        defaultValue={detail?.pincode || ""}
                        type="number"
                        sx={{ width: "100%" }}
                      />
                    </Grid>

                    <hr style={{ width: "100%" }} />

                    <Typography
                      sx={{ flex: "1 1 100%", ml: 2 }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      Emergency Details
                    </Typography>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="emergency_contact 1"
                        name="emergency_contact1"
                        type="tel"
                        onChange={(e) => handleEmergencyContact(1, e)}
                        defaultValue={detail.emergency_contact1 || " "}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="emergency_contact 2"
                        name="emergency_contact2"
                        type="tel"
                        onChange={(e) => handleEmergencyContact(2, e)}
                        defaultValue={detail.emergency_contact2 || " "}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="emergency_contact 3"
                        name="emergency_contact3"
                        type="tel"
                        onChange={(e) => handleEmergencyContact(3, e)}
                        defaultValue={detail.emergency_contact3 || " "}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="emergency_contact 4"
                        name="emergency_contact4"
                        type="tel"
                        onChange={(e) => handleEmergencyContact(4, e)}
                        defaultValue={detail.emergency_contact4 || " "}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="emergency_contact 5"
                        name="emergency_contact5"
                        type="tel"
                        onChange={(e) => handleEmergencyContact(5, e)}
                        defaultValue={detail.emergency_contact5 || " "}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Relation 1
                        </InputLabel>
                        <Select
                          value={
                            relative1 === "" ? detail?.relation1 : relative1
                          }
                          onChange={(e) => handleRelativeChange(1, e)}
                          label="Select Relation 1"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {relationOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Relation 2
                        </InputLabel>
                        <Select
                          value={
                            relative2 === "" ? detail?.relation2 : relative2
                          }
                          onChange={(e) => handleRelativeChange(2, e)}
                          label="Select Relation 2"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {relationOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Relation 3
                        </InputLabel>
                        <Select
                          value={
                            relative3 === "" ? detail?.relation3 : relative3
                          }
                          onChange={(e) => handleRelativeChange(3, e)}
                          label="Select Relation3"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {relationOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Relation 4
                        </InputLabel>
                        <Select
                          value={
                            relative4 === "" ? detail?.relation4 : relative4
                          }
                          onChange={(e) => handleRelativeChange(4, e)}
                          label="Select Relation3"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {relationOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Relation 5
                        </InputLabel>
                        <Select
                          value={
                            relative5 === "" ? detail?.relation5 : relative5
                          }
                          onChange={(e) => handleRelativeChange(5, e)}
                          label="Select Relation5"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {relationOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Gender
                        </InputLabel>
                        <Select
                          value={gender === "" ? detail.gender : gender}
                          onChange={handleGenderChange}
                          label="Select Gender"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value={"Female"}>Female</MenuItem>
                          <MenuItem value={"Others"}>Others</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Blood Group
                        </InputLabel>
                        <Select
                          value={
                            blood !== "" ? blood : detail?.blood_group || ""
                          }
                          onChange={handleBlood}
                          label="Select Blood Group"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value={"A+"}>A+</MenuItem>
                          <MenuItem value={"A-"}>A-</MenuItem>
                          <MenuItem value={"B+"}>B+</MenuItem>
                          <MenuItem value={"B-"}>B-</MenuItem>
                          <MenuItem value={"O+"}>O+</MenuItem>
                          <MenuItem value={"O-"}>O-</MenuItem>
                          <MenuItem value={"AB+"}>AB+</MenuItem>
                          <MenuItem value={"AB-"}>AB-</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <hr style={{ width: "100%" }} />
                    <Typography
                      sx={{ flex: "1 1 100%", ml: 2 }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      Vehicle Details
                    </Typography>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-chassis_number"
                        name="chassis_number"
                        label="chassis No"
                        type="text"
                        onChange={handleChasisno}
                        defaultValue={detail.chassis_number || " "}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-vehicle_no"
                        name="vechile_no"
                        label="Vechile No"
                        type="text"
                        value={detail?.vechile_no || ""}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-vehicle-type"
                        name="vechile_type"
                        label="Vechile Type"
                        type="text"
                        value={detail?.vechile_type || ""}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>

                    <hr style={{ width: "100%" }} />
                    <Typography
                      sx={{ flex: "1 1 100%", ml: 2 }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      Vehicle Insurace Details
                    </Typography>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-vechile_insurance_company_name"
                        name="vechile_insurance_company_name"
                        label="Vehicle Insurance Name"
                        // value={detail.detail && detail.detail.phone_number ? detail.detail.phone_number : " "}
                        onChange={handleVehicleCoName}
                        defaultValue={
                          detail?.vechile_insurance_company_name || ""
                        }
                        type="text"
                        sx={{ width: "100%" }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-vechile_insurance_no"
                        name="vechile_insurance_no"
                        label="Vehicle Insunarce No"
                        // value={detail.detail && detail.detail.phone_number ? detail.detail.phone_number : " "}
                        onChange={handleVehicleCoNo}
                        defaultValue={detail?.vechile_insurance_no || ""}
                        type="text"
                        sx={{ width: "100%" }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-v_insu_valid_to"
                        name="v_insu_valid_to"
                        label="Vehicle Insurance Valid From"
                        defaultValue={
                          moment(detail?.v_insu_valid_from).format(
                            "YYYY-MM-DD"
                          ) || ""
                        }
                        onChange={handleVehicleFrom}
                        type="date"
                        sx={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true, // Shrink the label when a date is selected
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-v_insu_valid_to"
                        name="v_insu_valid_to"
                        label="Vehicle Insurance Valid To"
                        defaultValue={
                          moment(detail?.v_insu_valid_to).format(
                            "YYYY-MM-DD"
                          ) || ""
                        }
                        onChange={handleVehicleTo}
                        type="date"
                        sx={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true, // Shrink the label when a date is selected
                        }}
                      />
                    </Grid>

                    <hr style={{ width: "100%" }} />

                    <Typography
                      sx={{ flex: "1 1 100%", ml: 2 }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      Health Insurance Details
                    </Typography>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-health_insurance_company_name"
                        name="health_insurance_company_name"
                        label="Health Insurance Name"
                        // value={detail.detail && detail.detail.phone_number ? detail.detail.phone_number : " "}
                        onChange={handleHealthCoName}
                        defaultValue={
                          detail?.health_insurance_company_name || ""
                        }
                        type="text"
                        sx={{ width: "100%" }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-health_insurance_no"
                        name="health_insurance_no"
                        label="Health Insurance No"
                        // value={detail.detail && detail.detail.phone_number ? detail.detail.phone_number : " "}
                        onChange={handleHealthCoNo}
                        defaultValue={detail?.health_insurance_no || ""}
                        type="text"
                        sx={{ width: "100%" }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-v_insu_valid_to"
                        name="h_insu_valid_to"
                        label="Health Insurance Valid From"
                        defaultValue={
                          moment(detail?.h_insu_valid_from).format(
                            "YYYY-MM-DD"
                          ) || ""
                        }
                        onChange={handleHealthCoFrom}
                        type="date"
                        sx={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true, // Shrink the label when a date is selected
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-v_insu_valid_to"
                        name="h_insu_valid_to"
                        label="Health Insurance Valid To"
                        defaultValue={
                          moment(detail?.h_insu_valid_to).format(
                            "YYYY-MM-DD"
                          ) || ""
                        }
                        onChange={handleHealthCoTo}
                        type="date"
                        sx={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true, // Shrink the label when a date is selected
                        }}
                      />
                    </Grid>

                    <hr style={{ width: "100%" }} />
                    <Typography
                      sx={{ flex: "1 1 100%", ml: 2 }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      QR Expiry Detail
                    </Typography>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-qrcodeId"
                        name="qrcodeId"
                        label="QR Code Id"
                        // onChange={handleTrackingNo}
                        defaultValue={detail?.qr_code_id || ""}
                        type="text"
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-expiry-date"
                        name="expiryDate"
                        label="Expiry Date"
                        // onChange={handleTrackingNo}
                        defaultValue={
                          moment(detail?.expiry_date).format("YYYY-MM-DD ") ||
                          ""
                        }
                        type="text"
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Button
                    style={{ padding: "10px", margin: "18px" }}
                    type="submit"
                    variant="contained"
                    onClick={submit}
                  >
                    Update Details
                  </Button>
                </form>
              </Card>
            )}
          </Paper>
        </Box>
      </Dashboard>
    </>
  );
}
