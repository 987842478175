import { React, useState } from "react";
import adminApiService from "../../services/admiApis.service";
import { Box, Toolbar, Typography, Card, Grid, TextField, Paper, Button } from "@mui/material";

import notify from "../notification/notification";
import Dashboard from "../dashboard";

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
        Add Vehicle
      </Typography>
    </Toolbar>
  );
};

export default function AddVehicle() {
  const [vechileType, setVehicleType] = useState("");
  const [amount, setAmount] = useState("");
  const [validityInMonth, setValidityInMonth] = useState("");

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleValidityInMonth = (event) => {
    setValidityInMonth(event.target.value);
  };

  const handleVehicleType = (event) => {
    setVehicleType(event.target.value.toUpperCase());
  };

  const createVehicleType = async (e) => {
    e.preventDefault();
    if (!vechileType || !amount || !validityInMonth) {
      alert("Please enter the amount, validity and vehicle type");
      return;
    }
    const payload = {
      vechile_type: vechileType,
      amount: amount,
      validity_in_month: validityInMonth,
    };
    try {
      const res = await adminApiService.addVehicle(payload);
      if (res.success) {
        notify.sucess("Vehicle Added Successfully");
        setVehicleType("");
        setAmount("");
        setValidityInMonth(null);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Dashboard>
        <Box className="content-wrapper" mt={2}>
          <Paper sx={{ mr: 1, ml: 1, mb: 2 }}>
            <EnhancedTableToolbar />
            <Card className="mt-2">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                  <TextField id="standard-basic" label="Amount" name="amount" type="number" onChange={handleAmountChange} sx={{ width: "100%" }} />
                </Grid>

                <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                  <TextField
                    id="standard-basic"
                    label="Validity (in months)"
                    name="validityInMonth"
                    type="number"
                    InputProps={{ min: "1", max: "12" }}
                    onChange={handleValidityInMonth}
                    sx={{ width: "100%" }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                  <TextField
                    id="standard-basic-vechile_type"
                    label="Vehicle Type"
                    name="vechile_type"
                    type="text"
                    InputProps={{ min: "1", max: "12" }}
                    onChange={handleVehicleType}
                    sx={{ width: "100%" }}
                  />
                </Grid>
              </Grid>

              <Typography align="left" sx={{ mt: 6, mb: 6, ml: 2 }}>
                <Button type="submit" variant="contained" color="primary" size="large" onClick={createVehicleType}>
                  Create Vehicle
                </Button>
              </Typography>
            </Card>
          </Paper>
        </Box>
      </Dashboard>
    </>
  );
}
