import React, { useState, useEffect } from "react";
import adminApiService from "../../services/admiApis.service";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory } from "react-router-dom";
import {
  Box,
  Toolbar,
  Typography,
  Card,
  Grid,
  TextField,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Button,
} from "@mui/material";
import { useParams } from "react-router-dom";
import notify from "../notification/notification";
import moment from "moment";
// import {  useHistory } from 'react-router';

import Dashboard from "../dashboard";

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        View Customer Order Details
      </Typography>
    </Toolbar>
  );
};

export default function ViewOrderCustomer() {
  const history = useHistory();
  const [detail, setDeatil] = useState({});
  const [gender, setGender] = useState("");
  const [relative, setRelative] = useState({
    relation1: "",
    relation2: "",
    relation3: "",
    relation4: "",
    relation5: ""
  });
  const [blood, setBlood] = useState("");
  const [vType, setVType] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [courierStatus, setcourierStatus] = useState("");
  const [vehicleTypeData, setVehicleTypeData] = useState([]);
  const [orderQrId, setOrderQrId] = useState("");
  const [formfield, setFormfield] = useState({
    name: "",
    email: "",
    mobile_no: "",
    vechile_no: "",
    vehicle_type: "",
    emergency_contact1: "",
    emergency_contact2: "",
    emergency_contact3: "",
    emergency_contact4: "",
    emergency_contact5: "",
    relation1: "",
    relation2: "",
    relation3: "",
    relation4: "",
    relation5: "",
    street: "",
    district: "",
    state: "",
    area: "",
    building_name: "",
    building_no: "",
    pincode: "",
    gender: "",
    razorpay_payment_id: "",
    blood_group: "",
    razorpay_order_id: "",

    tracking_number: "",
    courier_company_name: "",
    chassis_number: "",
    payment_status: "",
  });

  const [order, setOrder] = useState({});
  const [status, setStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [storeQrId, setStoreQrId] = useState(null);
  const [relationOptions, setRelationOptions] = useState([]);
  const params = useParams();
  // const history = useHistory();
  const handleGenderChange = (e) => {
    const selectedGender = e.target.value;
    setGender(selectedGender);
    // setGender(event.target.value);
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      gender: e.target.value,
    }));
  };
  const handleCourierChange = (event) => {
    const selectedValue = event.target.value;
    setcourierStatus(selectedValue);
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      courier_status: event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formfield);
  };
  const handleName = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      name: e.target.value,
    }));
  };
  const handleBuildingName = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      building_name: e.target.value,
    }));
  };
  const handleBuildingNo = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      building_no: e.target.value,
    }));
  };
  const handleEmail = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      email: e.target.value,
    }));
  };
  const handlePhone = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      mobile_no: e.target.value,
    }));
  };
  
  const handleEmergencyContact = (contactNumber, e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      [`emergency_contact${contactNumber}`]: e.target.value,
    }));
  };

  const handleRelativeChange = (e, relationKey) => {
    const selectedRelative = e.target.value;
    setRelative((prevRelative) => ({
      ...prevRelative,
      [relationKey]: selectedRelative,
    }));
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      [relationKey]: selectedRelative,
    }));
  };

  const handleStreet = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      street: e.target.value,
    }));
  };
  const handleState = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      state: e.target.value,
    }));
  };
  const handleArea = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      area: e.target.value,
    }));
  };
  const handleDistrict = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      district: e.target.value,
    }));
  };
  const handlePincode = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      pincode: e.target.value,
    }));
  };
  const handleBlood = (e) => {
    const selectedBlood = e.target.value;
    setBlood(selectedBlood);
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      blood_group: selectedBlood,
    }));
  };

  const handleRazorpayId = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      razorpay_order_id: e.target.value,
    }));
  };

  const handleRazorpaymentId = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      razorpay_payment_id: e.target.value,
    }));
  };
  const handleVehicleNo = (e, index) => {
    // console.log(e.target.value,index);
    let selectedVal = vehicleTypeData;
    selectedVal[index].vechile_no = e.target.value;
    setVehicleTypeData(selectedVal);
    //  console.log(e.target.value);
    //const filteredVehicleData = order.qr_codes.find((item)=>item.qr_code_id===e.target.value);
    //console.log("filteredVehicleData", filteredVehicleData);

    // setFormfield((prevFormfield) => ({
    //   ...prevFormfield,
    //   vechile_no: e.target.value,
    // }));
  };
  const handleChasisno = (e, index) => {
    //  console.log(e.target.value,index);
    let selectedVal = vehicleTypeData;
    selectedVal[index].chassis_number = e.target.value;
    setVehicleTypeData(selectedVal);
    // setFormfield((prevFormfield) => ({
    //   ...prevFormfield,
    //   chassis_number: e.target.value,
    // }));
  };
  const handleQty = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      quantity: e.target.value,
    }));
  };
  const handlePaymentAmount = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      payment_amount: e.target.value,
    }));
  };

  const handleOrderStatus = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      order_status: e.target.value,
    }));
  };

  const handleCourierName = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      courier_company_name: e.target.value,
    }));
  };
  const handleTrackingNo = (e) => {
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      tracking_number: e.target.value,
    }));
  };

  useEffect(() => {
    async function fetchVehicleType() {
      const res = await adminApiService.getVehicleType();
      //  console.log(res);
      // Extract vehicle types from the response
      const types = res.response.map((item) => item.vechile_type);

      // Set the vehicle types in state
      setVehicleType(types);

      //  console.log(vehicleType);
    }
    fetchVehicleType().catch(console.error);
  }, []);

  useEffect(() => {
    async function fetchRelationData() {
      const res = await adminApiService.getRelation();
      const relationTypes = res.response.map((item) => item.relation);
      //  console.log(relationTypes);
      setRelationOptions(relationTypes);
    }
    fetchRelationData().catch(console.error);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const res = await adminApiService.viewOrder(params.orderNo);
      if (res.success) {
        const qrCodes = res.response.qr_codes; // Assuming qr_codes is an array in the response

        // Assuming you want to update state for all qr_codes
        const updatedQrCodes = qrCodes.map((qrCode) => {
          return {
            order_qr_id: qrCode.order_qr_id,
            vechile_type: qrCode.vechile_type,
            // Add other properties you want to update here
          };
        });
        let resData = res.response.qr_codes.map((item) => item.vechile_type);

        setVehicleTypeData(res.response.qr_codes);
        setOrder({ ...res.response });

        setStoreQrId(updatedQrCodes.qr_code_id); // Update the first qr_code_id if needed
        setOrderQrId(updatedQrCodes.order_qr_id); // Update the first order_qr_id if needed
        // console.log("setOrderQrId", updatedQrCodes[0].order_qr_id);
        // console.log("vehicle type", updatedQrCodes[0].vechile_type);

        // Now you have an array of updated qr_codes with their respective order_qr_id values
        // console.log("Updated QR Codes:", updatedQrCodes);
      }
    }

    fetchData();
  }, [params.orderNo]);

  const handleChange = (event) => {
    setStatus(event.target.value);
    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      payment_status: event.target.value,
    }));
  };

  const handleOrderStatusChange = (event) => {
    setOrderStatus(event.target.value);
  };

  const handleVehicleType = (event, index) => {
    //console.log(event.target.value,index);
    let selectedVal = vehicleTypeData;
    selectedVal[index].vechile_type = event.target.value;
    setVehicleTypeData(selectedVal);
    // setVType(event.target.value);

    setFormfield((prevFormfield) => ({
      ...prevFormfield,
      vehicle_type: event.target.value,
    }));
  };

  const updatePaymentStatus = async (event) => {
    // if (status === "") {
    //   alert("Please select status");
    //   return;
    // }
    // console.log(params.orderNo);
    const data = {
      order_id: params.orderNo,
      status: status,
    };
    try {
      const res = await adminApiService.updatePaymentStatus(data);
      // console.log("res",res);
      if (res.success) {
        notify.sucess("Updated Payment Status");
        // history.push("/");
      }
    } catch (err) {
      notify.error("Updated Payment Status Failed");
      // console.log(err);
    }
  };

  const [role, setRole] = useState("");

  useEffect(() => {
    setRole(JSON.parse(localStorage.getItem("adminUser")).role);
  }, []);

  const submit = async () => {
    const data = {
      order_id: params.orderNo,
      name: formfield.name,
      email: formfield.email,
      mobile_no: formfield.mobile_no,
      //  vechile_no: formfield.vechile_no,
      //  vehicle_type: formfield.vType,
      emergency_contact1: formfield.emergency_contact1,
      emergency_contact2: formfield.emergency_contact2,
      emergency_contact3: formfield.emergency_contact3,
      emergency_contact4: formfield.emergency_contact4,
      emergency_contact5: formfield.emergency_contact5,
      street: formfield.street,
      district: formfield.district,
      state: formfield.state,
      area: formfield.area,
      building_name: formfield.building_name,
      building_no: formfield.building_no,
      pincode: formfield.pincode,
      gender: formfield.gender,
      relation1: formfield.relation1,
      relation2: formfield.relation2,
      relation3: formfield.relation3,
      relation4: formfield.relation4,
      relation5: formfield.relation5,
      blood_group: formfield.blood_group,
      // street: formfield.street,
      district: formfield.district,
      state: formfield.state,
      area: formfield.area,
      building_name: formfield.building_name,
      building_no: formfield.building_no,
      pincode: formfield.pincode,
      razorpay_order_id: formfield.razorpay_order_id,
      razorpay_payment_id: formfield.razorpay_payment_id,

      quantity: formfield.quantity,
      payment_amount: formfield.payment_amount,
      order_status: orderStatus,
      tracking_number: formfield.tracking_number,
      courier_company_name: formfield.courier_company_name,
      courier_status: courierStatus,
      payment_status: status,
      // vechile_insurance_company_name: formfield.vechile_insurance_company_name,
      // vechile_insurance_no: formfield.vechile_insurance_no,
      // v_insu_valid_from: formfield.v_insu_valid_from,
      // v_insu_valid_to: formfield.v_insu_valid_to,
      // health_insurance_company_name: formfield.health_insurance_company_name,
      // health_insurance_no: formfield.health_insurance_no,
      // h_insu_valid_from: formfield.h_insu_valid_from,
      // h_insu_valid_to: formfield.h_insu_valid_to,
    };
    const res = await adminApiService.updateOrderDetails(data);
    if (res.success) {
      notify.sucess("Details updated successfully");
    } else {
      notify.error("update failed try again");
    }

    // if (status != "") {
    //   updatePaymentStatus();
    // }
    // if (courierStatus != "") {
    //   updateCourierStatus();
    // }
  };

  const updateCourierStatus = async (event) => {
    if (courierStatus === "") {
      alert("Please select status");
      return;
    }
    // if (courierStatus === "Dispatch" && formfield.courier_company_name ==="" || formfield.tracking_number ==="") {
    //   alert("Please Add Courier Name & Tracking No");
    //   return ;
    // }
    const data = {
      order_id: params.orderNo,
      status: courierStatus,
      courier_company_name: formfield.courier_company_name,
      tracking_number: formfield.tracking_number,
    };
    try {
      const res = await adminApiService.updateOrder(data);
      if (res.success == true) {
        // notify.sucess("Updated Courier Status");
        notify.sucess(res.message);
        // history.push(`/viewOrder/${params.orderNo}`);
        //history.push("/order");
      } else {
        notify.error(res.message);
      }
    } catch (err) {
      notify.error("Courier Status Update Failed");
      // console.log(err);
    }
  };
  const handleCourierStatus = () => {
    //e.stopPropagation();
    history.push(`/updateOrder/${params.orderNo}`);
  };
  const updateVehicleDetails = async (ele, index) => {
    let payload = {
      order_qr_id: vehicleTypeData[index].order_qr_id,
      vechile_type: vehicleTypeData[index].vechile_type,
      vechile_no: vehicleTypeData[index].vechile_no,
      chassis_number: vehicleTypeData[index].chassis_number,
    };
    const rdata = await adminApiService.updateVehicleDeetsOrder(payload);
    //console.log("text",payload);
    if (rdata.success) {
      // console.log("data", rdata);
      notify.sucess(rdata.message);
    } else {
      notify.sucess(rdata.message);
    }
  };
  return (
    <div>
      <Dashboard>
        <Box className="content-wrapper" sx={{ mb: 2, mt: 2 }}>
          <Paper sx={{ mr: 1, ml: 1, mb: 2 }}>
            <EnhancedTableToolbar />
            {Object.keys(order).length > 0 && (
              // this.status=order.order.payment_status,
              <Card className="mt-2">
                <form onSubmit={handleSubmit}>
                  <Grid container mb={2}>
                    <Typography
                      sx={{ flex: "1 1 100%", ml: 2 }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      Customer Details
                    </Typography>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="Name"
                        name="name"
                        type="text"
                        onChange={handleName}
                        //  value={ formfield.name}

                        defaultValue={order.order.name || " "}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="Phone Numbers"
                        name="phone_number"
                        type="tel"
                        onChange={handlePhone}
                        defaultValue={order.order.phone_number || " "}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic"
                        label="Email"
                        name="email"
                        type="email"
                        onChange={handleEmail}
                        defaultValue={order.order.email || " "}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="Emergency Contact 1"
                        name="emergency_contact1"
                        type="tel"
                        onChange={(e) => handleEmergencyContact(1, e)}
                        defaultValue={order.order.emergency_contact1 || " "}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="Emergency Contact 2"
                        name="emergency_contact2"
                        type="tel"
                        onChange={(e) => handleEmergencyContact(2, e)}
                        defaultValue={order.order.emergency_contact2 || " "}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="Emergency Contact 3"
                        name="emergency_contact3"
                        type="tel"
                        onChange={(e) => handleEmergencyContact(3, e)}
                        defaultValue={order.order.emergency_contact3 || " "}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="Emergency Contact 4"
                        name="emergency_contact4"
                        type="tel"
                        onChange={(e) => handleEmergencyContact(4, e)}
                        defaultValue={order.order.emergency_contact4 || " "}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="Emergency Contact 5"
                        name="emergency_contact5"
                        type="tel"
                        onChange={(e) => handleEmergencyContact(5, e)}
                        defaultValue={order.order.emergency_contact5 || " "}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Relation 1
                        </InputLabel>
                        <Select
                          value={
                            relative.relation1 === ""
                              ? order.order?.relation1
                              : relative.relation1
                          }
                          onChange={(e) => handleRelativeChange(e, "relation1")}
                          label="Select Relation 1"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {relationOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Relation 2
                        </InputLabel>
                        <Select
                          value={
                            relative.relation2 === ""
                              ? order.order?.relation2
                              : relative.relation2
                          }
                          onChange={(e) => handleRelativeChange(e, "relation2")}
                          label="Select Relation 2"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {relationOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Relation 3
                        </InputLabel>
                        <Select
                          value={
                            relative.relation3 === ""
                              ? order.order?.relation3
                              : relative.relation3
                          }
                          onChange={(e) => handleRelativeChange(e, "relation3")}
                          label="Select Relation3"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {relationOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Relation 4
                        </InputLabel>
                        <Select
                          value={
                            relative.relation4 === ""
                              ? order.order?.relation4
                              : relative.relation4
                          }
                          onChange={(e) => handleRelativeChange(e, "relation4")}
                          label="Select Relation4"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {relationOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Relation 5
                        </InputLabel>
                        <Select
                          value={
                            relative.relation5 === ""
                              ? order.order?.relation5
                              : relative.relation5
                          }
                          onChange={(e) => handleRelativeChange(e, "relation5")}
                          label="Select Relation5"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {relationOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Gender
                        </InputLabel>
                        <Select
                          value={gender === "" ? order.order?.gender : gender}
                          onChange={handleGenderChange}
                          label="Select Gender"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value={"Female"}>Female</MenuItem>
                          <MenuItem value={"Others"}>Others</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Blood Group
                        </InputLabel>
                        <Select
                          value={
                            blood !== ""
                              ? blood
                              : order.order?.blood_group || ""
                          }
                          onChange={handleBlood}
                          label="Select Blood Group"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value={"A+"}>A+</MenuItem>
                          <MenuItem value={"A-"}>A-</MenuItem>
                          <MenuItem value={"B+"}>B+</MenuItem>
                          <MenuItem value={"B-"}>B-</MenuItem>
                          <MenuItem value={"O+"}>O+</MenuItem>
                          <MenuItem value={"O-"}>O-</MenuItem>
                          <MenuItem value={"AB+"}>AB+</MenuItem>
                          <MenuItem value={"AB-"}>AB-</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-status"
                        name="status"
                        label="Order Status"
                        defaultValue={order.order.order_status || " "}
                        type="text"
                        onChange={handleOrderStatus}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid> */}

                    {/* <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField
                      id="standard-basic-payment_status"
                      name="payment_status"
                      label="Payment Status"
                      value={order.order.payment_status}
                      type="text"
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    </Grid> */}

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-building-number"
                        name="building-number"
                        label="Building/Flat Number"
                        onChange={handleBuildingName}
                        defaultValue={order.order.building_no || " "}
                        type="text"
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-building-name"
                        name="building-name"
                        label="Building/Flat Name"
                        onChange={handleBuildingNo}
                        defaultValue={order.order.building_name || " "}
                        type="text"
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-street"
                        name="street"
                        label="Address Line 1"
                        onChange={handleStreet}
                        defaultValue={order.order.street || " "}
                        type="text"
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-area"
                        name="area"
                        label="Address Line 2"
                        onChange={handleArea}
                        defaultValue={order.order.area || " "}
                        type="text"
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-district"
                        name="district"
                        label="District/City"
                        onChange={handleDistrict}
                        defaultValue={order.order.district || " "}
                        type="text"
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-state"
                        name="State"
                        label="State"
                        onChange={handleState}
                        defaultValue={order.order.state || " "}
                        type="text"
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-pincode"
                        name="pincode"
                        label="Pincode"
                        onChange={handlePincode}
                        defaultValue={order.order.pincode || " "}
                        type="number"
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <hr style={{ width: "100%" }} />
                    <Typography
                      sx={{ flex: "1 1 100%", ml: 2 }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      Payment Details
                    </Typography>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-quantity"
                        name="quantity"
                        label="Quantity"
                        type="number"
                        onChange={handleQty}
                        defaultValue={order.order.quantity || " "}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-amount"
                        name="amount"
                        label="Amount"
                        type="number"
                        onChange={handlePaymentAmount}
                        value={order.order.payment_amount || " "}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-razorpay_order_id"
                        name="razorpay_order_id"
                        label="RazorPay Order Id"
                        onChange={handleRazorpayId}
                        defaultValue={order.order.razorpay_order_id || " "}
                        type="text"
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-razorpay_order_id"
                        name="razorpay_payment_id"
                        label="RazorPay Payment Id"
                        onChange={handleRazorpaymentId}
                        defaultValue={order?.order.razorpay_payment_id || " "}
                        type="text"
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    {/* <Grid container spacing={3} sx={{ pl: 2 }}> */}

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Payment Status
                        </InputLabel>
                        <Select
                          value={
                            status === "" ? order.order.payment_status : status
                          }
                          onChange={handleChange}
                          label="Select Status"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value={"Success"}>Success</MenuItem>
                          <MenuItem value={"Failed"}>Failed</MenuItem>
                          <MenuItem value={"Refunded"}>Refunded</MenuItem>
                          <MenuItem value={"Pending"}>Pending</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Order Status
                        </InputLabel>
                        <Select
                          value={
                            orderStatus === ""
                              ? order.order.order_status
                              : orderStatus
                          }
                          onChange={handleOrderStatusChange}
                          label="Select Order Status"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value={"Active"}>Active</MenuItem>
                          <MenuItem value={"Pending"}>Pending</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <hr style={{ width: "100%" }} />
                    <Typography
                      sx={{ flex: "1 1 100%", ml: 2 }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      Courier Details
                    </Typography>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-tracking_number"
                        name="tracking_number"
                        label="Tracking No."
                        onChange={handleTrackingNo}
                        defaultValue={order.order.tracking_number || " "}
                        type="text"
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-courier_company_name"
                        name="courier_company_name"
                        label="Courier Name"
                        onChange={handleCourierName}
                        defaultValue={order.order.courier_company_name || " "}
                        type="text"
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-courier_company_name"
                        name="created At"
                        label="Created Date & Time"
                        // onChange={handleCourierName}
                        defaultValue={moment(order.order.createdAt).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                        type="text"
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="standard-basic-courier_company_name"
                        name="Updated At"
                        label="Updated Date & Time"
                        // onChange={handleCourierName}
                        defaultValue={moment(order.order.updatedAt).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                        type="text"
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Courier Status
                        </InputLabel>
                        <Select
                          value={
                            courierStatus === ""
                              ? order.order.courier_status
                              : courierStatus
                          }
                          onChange={handleCourierChange}
                          label="Select Status"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value={"Delivered"}>Delivered</MenuItem>
                          <MenuItem value={"Dispatch"}>Dispatch</MenuItem>
                          <MenuItem value={"Returned"}>Returned</MenuItem>
                          {/* <MenuItem value={"Active"}>Active</MenuItem> */}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* <IconButton onClick={handleCourierStatus}>
                    <EditIcon
                      sx={{ height: "50px", width: "40px", mt: 2, ml: 2 }}
                    />
                  </IconButton> */}
                  </Grid>
                  <Button
                    style={{ padding: "10px", margin: "18px" }}
                    type="submit"
                    variant="contained"
                    onClick={submit}
                  >
                    Update Details
                  </Button>
                  <Typography
                    sx={{ flex: "1 1 100%", ml: 2 }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                  >
                    Vehicle & QR Details
                  </Typography>
                  <Grid container mb={2}>
                    {order.qr_codes.length > 0 &&
                      order.qr_codes.map((ele, index) => {
                        return (
                          <React.Fragment key={ele.qr_code_id}>
                            <hr style={{ width: "100%" }} />

                            <Grid
                              item
                              xs={4}
                              sm={12}
                              lg={3}
                              sx={{ ml: 2, mt: 2 }}
                            >
                              <TextField
                                id="outlined-basic-qr_code_id"
                                label={`QR Code Id-${index + 1}`}
                                name="qr_code_id"
                                type="text"
                                value={ele.qr_code_id}
                                sx={{ width: "100%" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sm={6}
                              lg={3}
                              sx={{ ml: 2, mt: 2 }}
                            >
                              <TextField
                                id="standard-basic-vechile_No"
                                name="vechile_No"
                                label={`Vehicle Number-${index + 1}`}
                                type="text"
                                onChange={(e) => handleVehicleNo(e, index)}
                                defaultValue={ele.vechile_no || " "}
                                sx={{ width: "100%" }}
                                InputProps={{
                                  readOnly: false,
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sm={6}
                              lg={3}
                              sx={{ ml: 2, mt: 2 }}
                            >
                              <TextField
                                id="standard-basic-chassis_number"
                                name="chassis_number"
                                label={`chassis No-${index + 1}`}
                                type="text"
                                onChange={(e) => handleChasisno(e, index)}
                                defaultValue={ele.chassis_number || " "}
                                sx={{ width: "100%" }}
                                InputProps={{
                                  readOnly: false,
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sm={6}
                              lg={3}
                              sx={{ ml: 2, mt: 2 }}
                            >
                              <FormControl
                                fullWidth
                                name={`demo-simple-select-label1${index + 1}`}
                              >
                                <InputLabel
                                  id={`demo-simple-select-label${index + 1}`}
                                >
                                  Select Vehicle Type
                                </InputLabel>
                                <Select
                                  name={`demo-simple-select-label1${index + 1}`}
                                  // value={selectedVehicleTypes[index] === "" ? ele.vechile_type :selectedVehicleTypes[index]}
                                  value={
                                    vehicleTypeData[index]?.vechile_type || ""
                                    // vehicleTypeData[index].vechile_type
                                    // console.log(`vehicleTypeData[${index}].vechile_type: `, vehicleTypeData[index].vechile_type);
                                  }
                                  onChange={(e) => handleVehicleType(e, index)}
                                  label={`Select Status${index}`}
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  {vehicleType.map((type) => (
                                    <MenuItem key={type} value={type}>
                                      {type}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              lg={6}
                              sx={{
                                ml: 2,
                                mt: 2,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                variant="contained" // onClick={updatePaymentStatus}>
                                onClick={() => updateVehicleDetails(ele, index)}
                              >
                                Save
                              </Button>
                            </Grid>
                            {/* <Grid
                              item
                              xs={12}
                              sm={12}
                              lg={6}
                              sx={{ ml: 2, mt: 2 }}
                            >
                              <TextField
                                id="outlined-basic-qr_code"
                                label={`QR Code-${index + 1}`}
                                name="qr_code"
                                type="text"
                                value={ele.qr_code}
                                sx={{ width: "100%" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid> */}
                          </React.Fragment>
                        );
                      })}
                  </Grid>

                  {/* <Grid item xs={12} sm={6} lg={12} sx={{ ml: 2, mt: 2 }}> */}
                  {/* {role == "Admin" ? (
                      <Button
                        variant="contained" // onClick={updatePaymentStatus}>
                        onClick={(event) => updatePaymentStatus(event)}
                        disabled={status === "" ? true : false}
                      >
                        Update Payement Status
                      </Button>
                    ) : null} */}

                  {/* { role == "Admin" ?   <Button sx={{ ml: 2 }}
                      variant="contained" // onClick={updatePaymentStatus}>
                      onClick={(event) => updatePaymentStatus(event)}
                     
                    >
                      Download Invoice
                    </Button> : null } */}
                  {/* </Grid> */}
                </form>
              </Card>
            )}
          </Paper>
        </Box>
      </Dashboard>
    </div>
  );
}
