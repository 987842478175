import { React, useState, useEffect } from "react";
import { TextField, Dialog, DialogTitle, DialogActions } from "@mui/material";
import "./Modal.css";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  IconButton,
  TablePagination,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import adminApiService from "../../services/admiApis.service";
import { useHistory } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Dashboard from "../dashboard";
import moment from "moment";
import { Popover, Button } from "@mui/material";
import notify from "../notification/notification";


const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: "#01579b",
    "& .MuiTableSortLabel-icon": {
      color: "#ffffff !important",
    },
  },
  tabelHeaderCell: {
    color: "#ffffff !important",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "qr_code_id",
    numeric: false,
    disablePadding: false,
    label: "QR Code Id",
    isSort: true,
  },
  {
    id: "mobile_no",
    numeric: false,
    disablePadding: false,
    label: "Phone Number",
    isSort: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "name",
    isSort: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "vechile_no",
    numeric: false,
    disablePadding: false,
    label: "Vehicle Number",
  },
  {
    id: "blood_group",
    numeric: false,
    disablePadding: false,
    label: "Blood Group",
  },
  {
    id: "emergency_contact",
    numeric: false,
    disablePadding: false,
    label: "Emergency Contact",
  },
  {
    id: "relation",
    numeric: false,
    disablePadding: false,
    label: "Relation",
  },
  {
    id: "vechile_insurance_company_name",
    numeric: false,
    disablePadding: false,
    label: "Vehicle Insurance Name",
  },
  {
    id: "vechile_insurance_no",
    numeric: true,
    disablePadding: false,
    label: "Vehicle Insunarce No",
  },
  {
    id: "v_insu_valid_from",
    numeric: false,
    disablePadding: false,
    label: "Vehicle Insurance Valid From",
  },
  {
    id: "v_insu_valid_to",
    numeric: false,
    disablePadding: false,
    label: "Vehicle Insurance Valid To",
  },
  {
    id: "health_insurance_company_name",
    numeric: false,
    disablePadding: false,
    label: "Health Insurance Name",
  },
  {
    id: "health_insurance_no",
    numeric: true,
    disablePadding: false,
    label: "Health Insurance No",
  },
  {
    id: "h_insu_valid_from",
    numeric: false,
    disablePadding: false,
    label: "Health Insurance Valid From",
  },
  {
    id: "h_insu_valid_to",
    numeric: false,
    disablePadding: false,
    label: "Health Insurance Valid To",
  },
  {
    id: "",
    numeric: false,
    disablePadding: false,
    label: "Replace Qr ",
  },
  // {
  //   id: "update_details",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Update Details",
  // },

  // {
  //   id: "vechile_no",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Building No",
  // },
  // {
  //   id: "vechile_no",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Building/Flat Name",
  // },
  // {
  //   id: "vechile_no",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Street",
  // },
  // {
  //   id: "vechile_no",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Area",
  // },
  // {
  //   id: "vechile_no",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "District/City",
  // },
  // {
  //   id: "vechile_no",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "State",
  // },
  // {
  //   id: "vechile_no",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Pincode",
  // },
];

function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className={classes.tableHeader}>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              style={{ textAlign: "center" }}
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.isSort ? (
                <>
                  <TableSortLabel
                    className={classes.tabelHeaderCell}
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </>
              ) : (
                <span className="table-headCell" style={{ color: "#fff" }}>
                  {headCell.label}
                </span>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

export default function CustomerDetailsList() {
  const history = useHistory();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("qr_code_id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [qrList, setQRList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchedVal, setSearchedVal] = useState("");
  const [paginationCount, setPaginationCount] = useState(1);
  const dataList = [];
  const [phoneNumber, setPhoneNumber] = useState("");

  const fetchData = async () => {
    //  console.log(qr_code_id,page,size);

    const res = await adminApiService.getCustomerDetails(
      page,
      rowsPerPage,
      searchedVal
    );
    //  console.log(res.response);
    if (res.success) {
      setPaginationCount(res.response.count);

      setQRList(res.response.customer);
      setFilterData(res.response.customer);
    } else {
      setQRList([]);
      setFilterData([]);
    }
  };

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState("");

  const [navigationId, setNavigationId] = useState();
  const [storePhone, setStorePhone] = useState(null);
  const [tempQrId,setTempQrId] = useState();;

  useEffect(() => {
    fetchData().catch(console.error);
  }, [page, rowsPerPage, searchedVal]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePopoverOpen = (event,id) => {
    setTempQrId(id);
    // console.log("event",id);
    // console.log("temp-qr",tempQrId);

    // console.log("entering open pop over");
    setPopoverOpen(true);
    setPopoverAnchor(event.currentTarget);
  };
  const handlePopoverClose = () => {
   // console.log("closed open pop over");
    setPopoverOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // console.log(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    // console.log(parseInt(event.target.value));
  };

  const viewQRDetails = (event, data) => {
    history.push(`/viewQRDetails/${data.qr_code}`);
  };

  // const handlePopoverOpen = () => {
  //   console.log('Popover opened');
  //   setPopoverOpen(true);
  // };

  // const handlePopoverClose = () => {
  //   setPopoverOpen(false);
  // };
  // const handleSearch = (event) => {
  //   setPage(0);
  //   setSearchedVal(event.target.value);
  //   // setSearchedVal(event.target.value);
  //   // const filterData = qrList.filter((ele) => {
  //   //   return (
  //   //     ele.qr_code_id.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()) || ele.name.toString().toLowerCase().includes(event.target.value.toString().toLowerCase())
  //   //     || ele.phoneNumber.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()) || ele.vechile_no.toString().toLowerCase().includes(event.target.value.toString().toLowerCase())
  //   //   );
  //   // });
  //   // setFilterData(filterData);
  // };

  const handleSearch = (event) => {
    setPage(0);
    setSearchedVal(event.target.value);
  };
  // const viewQRDetails = (event, data) => {
  //   history.push(`/viewQRDetails/${data.qr_code}`);
  // };
  // setNavigationId(row.id);
  const goToUpdate = async (event, navigationId) => {
    // setNavigationId(row.id);
    history.push(`/updateCustomerDetails/${navigationId}`);
  };
  const handleOtp = async (row) => {
    setNavigationId(row.id);
    setStorePhone(row.mobile_no);
    // console.log("otp check");
    let data = {
      mobile: row.mobile_no,
    };
    const res = await adminApiService.sendOtp(data);
    // console.log(res);

    if (res.success === "true") {
      window.alert(res.message);
      setPopoverOpen(true);
    } else {
      window.alert(res.message);
    }
  };

  const handleVerify = async (row) => {
    let data = {
      mobile: storePhone,
      otp: otp,
    };
    const res = await adminApiService.getOtp(data);
    // console.log(res)
    // console.log(res.message,res.status,res.data);
    if (res.success === "true") {
      history.push(`/updateCustomerDetails/${navigationId}`);
    } else {
      window.alert(res.message);
    }
    // TODO: Add your verification logic here
    //  console.log("OTP Verified!");
    ///  setDialogOpen(true); // Open dialog for success message or next steps
  };

  const [otp, setOTP] = useState("");
  const [newQrId ,setNewQrId] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleNewQrID = (event) => {
    setNewQrId(parseInt(event.target.value));
   // console.log(newQrId);
  };

  const handleAssignNewQr = async () => {
  //  console.log(row);
    let data = {
      qr_code_id: tempQrId,
      new_qr_code_id: newQrId,
    };
    const res = await adminApiService.directReplacementAssignQr(data);
    // console.log(res)
    // console.log(res.message,res.status,res.data);
    if (res.success === "true") {
      notify.sucess(res.message);
     // console.log(res)
     // history.push(`/updateCustomerDetails/${navigationId}`);
    } else {
      notify.sucess(res.message);
    }
    // TODO: Add your verification logic here
    //  console.log("OTP Verified!");
    ///  setDialogOpen(true); // Open dialog for success message or next steps
  };

  const handleClose = () => {
    // TODO: Add your close logic here
    // console.log("Close Button Clicked");
    setDialogOpen(false); // Close dialog
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - qrList.length) : 0;

  const [modal, setModal] = useState(false);

  const toggleModal = (event,id) => {
    setTempQrId(id);
    // console.log("event",id);
    // console.log("temp-qr",tempQrId);

    // console.log("entering open pop over");
    // console.log("open modal");
    setModal(true);
   // setModal(!modal);
  };

  const toggleModalClose = ()=>{
    setModal(false);
    setNewQrId("");
  }
  // Define a function to call handlePopoverOpen
 

  return (
    <>
      <Dashboard>
        <Box className="content-wrapper" mt={2}>
          <Paper sx={{ mr: 1, ml: 1, mb: 2 }}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                mb: 2,
              }}
            >
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Customer Details List
              </Typography>
              <label htmlFor="search">Search</label>
              <input
                className="search-input-box"
                id="search"
                type="text"
                onChange={handleSearch}
                placeholder="Search by Phone-Number,name,vehicle-Number,QR-code-id"
              />
            </Toolbar>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={() => {}}
                  onRequestSort={handleRequestSort}
                  rowCount={qrList.length}
                />
                <TableBody>
                  {stableSort(filterData, getComparator(order, orderBy)).map(
                    (row, index) => {
                      // console.log("filterdat",row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          style={{ cursor: "pointer" }}
                          tabIndex={-1}
                          key={row.id}
                          // onClick={(event) => goToUpdate(event, row.id)}
                          // onLoad={() => callHandlePopoverOpen(row.id)} // Call the function when the TableRow loads
                        >
                          <TableCell    onClick={(event) => goToUpdate(event, row.id)}     align="center">{row.qr_code_id}</TableCell>
                          <TableCell     onClick={(event) => goToUpdate(event, row.id)} align="center">{row.mobile_no}</TableCell>
                          <TableCell    onClick={(event) => goToUpdate(event, row.id)} align="center">{row.name}</TableCell>
                          <TableCell   onClick={(event) => goToUpdate(event, row.id)}  align="center" id={labelId}>
                            {row.email}
                          </TableCell>
                          <TableCell   onClick={(event) => goToUpdate(event, row.id)}  align="center">{row.vechile_no}</TableCell>
                          <TableCell   onClick={(event) => goToUpdate(event, row.id)}  align="center">
                            {row.blood_group}
                          </TableCell>
                          <TableCell    onClick={(event) => goToUpdate(event, row.id)} align="center">
                            {row.emergency_contact1}
                          </TableCell>
                          <TableCell   onClick={(event) => goToUpdate(event, row.id)}  align="center">{row.relation1}</TableCell>
                          <TableCell   onClick={(event) => goToUpdate(event, row.id)}  align="center">
                            {row.vechile_insurance_company_name}
                          </TableCell>
                          <TableCell   onClick={(event) => goToUpdate(event, row.id)}  align="center">
                            {row.vechile_insurance_no}
                          </TableCell>
                          <TableCell  onClick={(event) => goToUpdate(event, row.id)}  align="center">
                            {/* {moment(row.v_insu_valid_to).format("DD/MM/YYYY")} */}
                            {row.v_insu_valid_from
                              ? moment(row.v_insu_valid_from).format(
                                  "DD/MM/YYYY"
                                )
                              : ""}
                          </TableCell>
                          <TableCell   onClick={(event) => goToUpdate(event, row.id)}  align="center">
                            {/* {moment(row.v_insu_valid_to).format("DD/MM/YYYY")} */}
                            {row.v_insu_valid_to
                              ? moment(row.v_insu_valid_to).format("DD/MM/YYYY")
                              : ""}
                          </TableCell>
                          <TableCell  onClick={(event) => goToUpdate(event, row.id)} align="center">
                            {row.health_insurance_company_name}
                            {/* {console.log(row.health_insurance_company_name)} */}
                          </TableCell>
                          <TableCell align="center"  onClick={(event) => goToUpdate(event, row.id)}>
                            {row.health_insurance_no}
                            {/* {console.log(row.health_insurance_no)} */}
                          </TableCell>
                          <TableCell align="center"  onClick={(event) => goToUpdate(event, row.id)}>
                            {/* {moment(row.h_insu_valid_from).format("DD/MM/YYYY")} */}
                            {row.h_insu_valid_from
                              ? moment(row.h_insu_valid_from).format(
                                  "DD/MM/YYYY"
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center"  onClick={(event) => goToUpdate(event, row.id)}>
                            {row.h_insu_valid_to
                              ? moment(row.h_insu_valid_to).format("DD/MM/YYYY")
                              : ""}
                          </TableCell>
                          <TableCell align="left">
                            {/* <Button style={{width:"153px"}}
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={()=>toggleModal()} className="btn-modal"
                              >
                                Add Replacement QR
                              </Button> */}
                            {/* <Button style={{width:"153px"}}
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={(event) =>handlePopoverOpen(event,row.qr_code_id)}
                              >
                                Add Replacement QR
                              </Button> */}
                            <Button
                              style={{ width: "153px" }}
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={(event) =>toggleModal(event,row.qr_code_id)}
                              
                            >
                              Add Replacement QR
                            </Button>
                          </TableCell>
                          {/* <TableCell align="left" onClick={(event) => viewQRDetails(event, row)}>{row.status ? row.status : "InActive"}</TableCell> */}
                          {/*  <TableCell align="left">
                            <IconButton onClick={(event) => viewReplacementQrDetails(event,row)} >
                            <RemoveRedEyeOutlinedIcon  color="primary" />
                            </IconButton> 
                            </TableCell>*/}
                        </TableRow>
                      );
                    }
                  )}

                  {/* {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={paginationCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>

        <>
          {modal && (
            <div className="modal">
              <div onClick={toggleModal} className="overlay"></div>
              <div className="modal-content">
                <h2>Assign Replacement Qr</h2>
                <>
                <div>
                <TextField
                  style={{ padding: "15px", marginBottom: "5px" }}
                  type="number"
                  value={newQrId}
                  onChange={handleNewQrID}
                  label="Enter New QR ID"
                  variant="outlined"
                  inputProps={{  pattern: "^[0-9]*$" }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                
                  style={{ padding: "5px", margin: "5px" }}
                  variant="contained"
                   onClick={handleAssignNewQr}
                >
                  Assign
                </Button>
                <Button
                  style={{ padding: "5px", margin: "5px" }}
                  variant="outlined"
                  onClick={toggleModalClose}
                >
                  Close
                </Button>
              </div>
                </>
               
                {/* <button className="close-modal" onClick={toggleModal}>
                  CLOSE
                </button> */}
              </div>
            </div>
          )}

          <Popover
            open={popoverOpen}
            // sx={{width:"00px"}}
            anchorEl={popoverAnchor}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
          >
            <div style={{ padding: "15px" }} className="overlay">
              <h2 style={{ textAlign: "center" }}>Assign Replacement Qr</h2>
              <div>
                <TextField
                  style={{ padding: "15px", marginBottom: "5px" }}
                  type="number"
                  value={newQrId}
                  onChange={handleNewQrID}
                  label="Enter New QR ID"
                  variant="outlined"
                  inputProps={{  pattern: "^[0-9]*$" }}
                />
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                
                  style={{ padding: "5px", margin: "5px" }}
                  variant="contained"
                   onClick={handleAssignNewQr}
                >
                  Assign QR ID
                </Button>
                <Button
                  style={{ padding: "5px", margin: "5px" }}
                  variant="outlined"
                  onClick={handlePopoverClose}
                >
                  Close
                </Button>
              </div>

              {/* <Dialog open={dialogOpen} onClose={handleClose}> */}
              {/* <DialogTitle>Success Message</DialogTitle> */}
              {/* <DialogActions>
          <Button align="center" onClick={handleClose}>OK</Button>
        </DialogActions> */}
              {/* </Dialog> */}
            </div>
          </Popover>
        </>
      </Dashboard>
    </>
  );
}
