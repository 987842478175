import { React, useState, useEffect } from "react";
import adminApiService from "../../services/admiApis.service";
import { Box, Toolbar, Typography, Container, Card, Grid, TextField, Paper, Button } from "@mui/material";

import { useHistory, useParams } from "react-router-dom";

import Dashboard from "../dashboard";

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
        View User Details
      </Typography>
    </Toolbar>
  );
};

export default function UserDetails() {
  const [userDetails, setUserDetails] = useState([]);
  const params = useParams();

  useEffect(async () => {
    try {
      const res = await adminApiService.userDetails(params.qrCode);
      if (res.success) {
        setUserDetails([...res.response]);
      } else {
        setUserDetails([]);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const { name, vechile_no, call_mobile, call_emergency, blood_group, path } = userDetails;

  return (
    <>
      <Dashboard>
        <Box>
          <Paper sx={{ mr: 1, ml: 1, mb: 2, mt: 2 }}>
            <EnhancedTableToolbar />
            {userDetails.length > 0 && (
              <Card sx={{ pb: 4 }}>
                <form autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="Name"
                        name="name"
                        type="text"
                        value={userDetails[0].name}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="Vehicle No"
                        name="vechile_no"
                        type="text"
                        value={userDetails[0].vechile_no}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="Mobile No"
                        name="call_mobile"
                        type="text"
                        value={userDetails[0].call_mobile}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        name="call_emergency"
                        label="Emergency No"
                        type="text"
                        value={userDetails[0].call_emergency}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        name="blood_group"
                        label="Blood Group"
                        type="text"
                        value={userDetails[0].blood_group}
                        sx={{ width: "100%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </form>
              </Card>
            )}
          </Paper>
        </Box>
      </Dashboard>
    </>
  );
}
